import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import Select from 'react-select'


const urlApi = '/storages';
const urlRouter = '/storage';
const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}`, 'accept': 'application/json' } }

export async function LoaderStorageUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const StorageForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [status, setStatus] = useState((data != undefined) ? data.status : '');
  const [description, setDescription] = useState((data != undefined) ? data.description : '');
  // const [parent, setParent] = useState((data != undefined) ? data.parent : '');
  const [c1id, setC1id] = useState((data != undefined) ? data.c1id : '');
  const [code1c, setCode1c] = useState((data != undefined) ? data.code1c : '');
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const [location, setLocation] = useState(false);
  const [locationList, setLocationList] = useState([]);
  // Запит за одиницями виміру
  const getLocations = async (inputValue) => {
    let rez = await AxiosInstance.get(`/locations`, { headers: { 'accept': 'application/json' } }).then((response) => {
      // console.log("response", response);
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }));
    });
    setLocationList(rez);
    return rez;
  };

  useEffect(() => {
    getLocations();
  }, [])

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      const data_response = {
        name: name,
        status: status,
        description: description,
        // parent: parent,
        c1id: c1id,
        code1c: code1c,
        active: 'on',
      };
      if (data != undefined && location != false) {
        data_response['location'] = data.location.map((val) => {
          return `/api/locations/${val.id}`;
        });


        data_response.location.push(`/api/locations/${location.value}`);
        // data_response.location.push(`/api/locations/${location.value}`);
      }


      if (data != undefined) {
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response, header).then((response) => {
          if (response.status === 200)
            return navigate(urlRouter);
        });
      } else {
        const response = AxiosInstance.post(`${urlApi}`, data_response, header).then((response) => {
          if (response.status === 201)
            return navigate(urlRouter);
        });
      }
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={status} onChange={(e) => setStatus(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off" />
        </div>
        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">parent</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={parent} onChange={(e) => setParent(e.target.value)} autoComplete="off" />
        </div> */}
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">c1id</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput5" value={c1id} onChange={(e) => setC1id(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">code1c</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput6" value={code1c} onChange={(e) => setCode1c(e.target.value)} autoComplete="off" />
        </div>

        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div>

        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
      <hr />
      {(data != undefined) ?
        <>
          <h6>Локація</h6>
          <div className="mb-3 d-flex">
            {/* <CFormLabel htmlFor="exampleFormControlInput2">Локація</CFormLabel> */}
            <Select
              options={locationList}
              value={location}
              onChange={(value) => {
                setLocation(value);
              }}
              className='w-25'
            />
            <CButton type="submit" onClick={(e) => handleSubmit(e)}>+</CButton>
          </div>
          <div className="mb-3">
            <ul>
              {data.location.map((l) => {
                return <li>{l.name}</li>
              })}
            </ul>
          </div>
        </>
        : ''}
    </>
  );
};

export default StorageForm;