import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import AxiosInstance from '../Axios';
import { CBadge, CListGroup, CListGroupItem, CButton, CCol, CRow, CFormCheck } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import ProductItemForSiteProducts from './ProductItemForSiteProducts';
import FilterProduct from '../filter/FilterProduct';

// Компонент для додавання, видалення та вивід доданих товарів до товару, який відобжається на сайті
const ProductsForSiteProducts = (props) => {
    // console.log('props ProductsForSiteProducts', props);

    const urlApi = '/site_products';

    // для очищення поля пошуку товарів
    const [clear, setClear] = useState(false);
    // Товари, які додані до товару на сайті
    const [addedProducts, setAddedProducts] = useState([]);
    // Товари, які додані до товару на сайті, записані у форматі PUT (`api/products/id`) для відправлення
    const [addedProductsIRI, setAddedProductsIRI] = useState([]);
    // Вибраний товар з пошуку
    const [selectedOption, setSelectedOption] = useState(null);
    // Масив з цінами доданих товарів
    const [priceArr, setPriceArr] = useState([]);
    // Чи оновлювати ціну товару на сайті
    // const [isUpdatePrice, setIsUpdatePrice] = useState(false);

    // Текст для popup
    const popupTextAddSuccess = "Товар додано";
    const popupTextDeleteSuccess = "Товар видалено";
    const popupTextError = "Помилка запиту";

    let i = 0;

    // Функція для заповнення продуктів, які додані до продукту на сайті, записані у форматі PUT (`api/attributs/id`) для відправлення
    const setProductsList = () => {
        // console.log('addedProducts', addedProducts);
        let oldProducts = [];
        // Наповнити масив з категорями, перетвеними у формат для API
        addedProducts.map(el => oldProducts.push(`api/products/${el.id}`));
        // console.log("oldProducts", oldProducts);
        setAddedProductsIRI(oldProducts);
    };

    // Функція для наповнення масиву цін
    const setPricesList = () => {
        let arrPrice = [];
        // Товари які продаються
        let productsForSell = [];
        productsForSell = addedProducts.filter(product => product.show);

        // addedProducts.map(product => arrPrice.push(product.price));
        productsForSell.map(product => arrPrice.push(product.price));
        arrPrice.sort(function (a, b) { return a - b });
        // console.log('arrPrice', arrPrice);
        setPriceArr(arrPrice);
        // console.log("addedProducts.products", addedProducts);
    };

    // Додати новий продукт
    const handleSave = () => {
        setClear(true);
        // Перевірка, чи вибраний продукт вже належить до даного продукту на сайті
        let enters = addedProducts.length > 0 ? addedProducts.some(el => el.id == selectedOption.value) : false;
        if (enters) {
            alert("Вибраний продукт вже належить до даного продукту який відображається на сайті");
        } else {
            // Вибрана нова категорія
            let newProduct = [`api/products/${selectedOption.value}`];
            // Об'єднати старі продукти (якщо є) і новий продукт
            let products = addedProductsIRI.length > 0 ? addedProductsIRI.concat(newProduct) : newProduct;
            // Дані, які підуть із запитом
            let article = {
                products: products
            };

            // Оновити товари для продукту
            AxiosInstance.put(`${urlApi}/${props.siteProduct_id}`, article).then((response) => {
                // console.log("response put", response);
                if (response.status == 200) {
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextAddSuccess);
                    // Оновити дані
                    props.setDataState(response.data);
                    // Оновити ціну товару на сайті
                    // setIsUpdatePrice(true);
                } else {
                    // console.log("Помилка запиту");
                    props.toastifyWarning(popupTextError);
                }
            });
        };
    };

    // Функція для видалення продукту від продукту який на сайті
    const deleteProduct = (id) => {
        let products = [];
        // Відфільтрувати категорію, яку потрібно видалити
        let filterProducts = addedProducts.filter(el => el.id !== id);
        // Наповнити масив з категорями, перетвеними у формат для API
        filterProducts.map(el => products.push(`api/products/${el.id}`));
        // Дані, які підуть із запитом
        let article = {
            products: products
        };

        // Оновити додані продукти
        AxiosInstance.put(`${urlApi}/${props.siteProduct_id}`, article).then((response) => {
            // console.log("response put", response);
            if (response.status == 200) {
                // Popup про успішні зміни
                props.toastifySuccess(popupTextDeleteSuccess);
                // Оновити дані
                props.setDataState(response.data);
                // Оновити ціну товару на сайті
                // setIsUpdatePrice(true);
            } else {
                // console.log("Помилка запиту");
                props.toastifyWarning(popupTextError);
            };
        });
    };

    // Функція для підтвердження подальшого видалення продукт від продукту який на сайті
    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        deleteProduct(row);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    // // Функція для оновлення ціни за продукт на сайті
    // const updatePrice = (price) => {
    //     AxiosInstance.put(`${urlApi}/${props.siteProduct_id}`, { price: price }).then((response) => {
    //         // console.log("response put", response);
    //         if (response.status == 200) {
    //             // Зупинити оновлення
    //             // setIsUpdatePrice(false);
    //             // Оновити дані
    //             props.setDataState(response.data);
    //         } else {
    //             // console.log("Помилка запиту");
    //             props.toastifyWarning(popupTextError);
    //         };
    //     });
    // };

    // Відслідковувати зміну доданих продукту на сайті
    useEffect(() => {
        if (props.dataState?.products && props.dataState.products.length > 0) {
            setAddedProducts(props.dataState.products);
        } else if (props.dataState?.products) {
            setAddedProducts([]);
        }
    }, [props.dataState]);
    // }, [props.dataState.products]);

    // Відслідковувати зміну продуктів, які додані до продукту на сайті
    useEffect(() => {
        if (addedProducts.length > 0) {
            // Заповнити список продуктів, які додані до продукту на сайті, записані у форматі PUT (`api/attributs/id`) для відправлення
            setProductsList();

            // Наповнити масив цін;
            setPricesList();
        };
    }, [addedProducts]);

    // Відслідковувати оновлення ціни товару на сайті
    useEffect(() => {
        // if (priceArr.length > 0 && isUpdatePrice) {
        if (priceArr.length > 0) {
            // updatePrice(priceArr[0]);
            props.setPrice(priceArr[0]);
            // } else if (priceArr.length == 0 && isUpdatePrice) {
        } else if (priceArr.length == 0) {
            // updatePrice(0);
            props.setPrice(0);
        }
        // }, [isUpdatePrice, priceArr]);
    }, [priceArr]);

    return (
        <div className="mb-3">
            <div className="row mb-2">
                <div className="col"><h3>Товари</h3></div>
                <div className="col d-flex">
                    <FilterProduct setData={setSelectedOption} clear={clear} />
                    <CButton onClick={handleSave} color="secondary" className='ms-2 mb-2'>+</CButton>
                </div>
            </div>

            {
                (addedProducts.length > 0) ?
                    <Accordion>
                        {
                            (addedProducts.length > 0) &&
                            addedProducts.map((product, index) => {
                                // console.log('product', product);
                                i++;
                                // Товари
                                return (
                                    <ProductItemForSiteProducts i={i} key={product.id} product={product} index={index} Deleted={Deleted} toastifySuccess={props.toastifySuccess} getData={props.getData} />
                                )
                            })
                        }
                    </Accordion>
                    :
                    <p className="ml-3 mb-3">Товарів не знайдено</p>
            }
        </div>
    );
};

export default ProductsForSiteProducts;