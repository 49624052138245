import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebarShow: true,
    price:[],
    balance: [],
    options: [],
    dateOption: '',
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setPrice: (state, action) => {
            state.price = action.payload;
        },
        switchSidebarShow: (state) => {
            state.sidebarShow = !state.sidebarShow
        },
        clearState: (state) => {
            state.price = []
        },
        setBalance: (state, action) => {
            state.balance = action.payload
        },
        setOptions: (state, action) => {
            state.options = action.payload
        },
        setDateOption: (state, action) => {
            state.dateOption = action.payload
        },
    }
})

export const {setPrice, switchSidebarShow, clearState, setBalance, setOptions, setDateOption} = orderSlice.actions

export default orderSlice.reducer