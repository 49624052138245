import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import AxiosInstance from '../Axios';
import { CListGroup, CListGroupItem, CButton } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
// import { Link } from 'react-router-dom';

// Додавання категорій до faq
const FaqsCategories = (props) => {
    // console.log("props", props);
    // props.faq_id - id-faq

    // Категорії, які додані до faq
    const [catItem, setCatItem] = useState([]);
    // Категорії, які додані до faq, записані у форматі PUT (`api/faqs/id`) для відправлення
    const [oldCat, setOldCat] = useState([]);
    // Вибрана категорія з пошуку
    const [selectedOption, setSelectedOption] = useState(null);
    // Текст для popup
    const popupTextPutSuccess = "Категорію додано";
    const popupTextDeleteSuccess = "Категорію видалено";
    const popupTextWarning = "Вибрана категорія вже належить до даного faq";

    let i = 0;
    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    // Завантаження всіх категорій для вибору з випадаючого списку
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get(`/categories?name=${inputValue}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };

    // Запит за категоріями, які вже додані до faq
    const getParent = async () => {
        AxiosInstance.get(`/categories?faqs.id=${props.faq_id}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            // console.log("response get faqs", response);
            setCatItem(response.data)
        });
    }
    useEffect(() => {
        getParent();
    }, [props]);

    // console.log("catItem для faq", catItem)

    // Заповнити категорії, які додані до faq, записані у форматі PUT (`api/faqs/id`) для відправлення
    useEffect(() => {
        if (catItem.length > 0) {
            let oldCategories = [];
            // Наповнити масив з категорями, перетвеними у формат для API
            catItem.map(el => oldCategories.push(`api/categories/${el.id}`));
            // console.log("oldCategories", oldCategories);
            setOldCat(oldCategories);
        }
        // console.log("catItem.categories", catItem);
    }, [catItem]);

    // Додати нову категорію
    const handleSave = () => {
        // Перевірка, чи вибрана категорія вже належить до даного faq
        let enters = catItem.length > 0 ? catItem.some(el => el.id == selectedOption.value) : false;
        if (enters) {
            // Popup про неуспішні зміни
            props.toastifyWarning(popupTextWarning);
        } else {
            // Вибрана нова категорія
            let newCategories = [`api/categories/${selectedOption.value}`];
            // Об'єднати старі категорії (якщо є) і нову категорію
            let categories = oldCat.length > 0 ? oldCat.concat(newCategories) : newCategories;
            // console.log('categories', categories);
            // Дані, які підуть із запитом
            let article = {
                category: categories
            };

            // Оновити категорії для faq
            AxiosInstance.put(`/faqs/${props.faq_id}`, article).then((response) => {
            // AxiosInstance.put(`/faqs/${props.faq_id}`, article, { headers: { 'accept': 'application/json' } }).then((response) => {
                // console.log("response put", response);
                if (response.status == 200) {
                    // Popup про успішні зміни
                    props.toastifySuccess(popupTextPutSuccess);
                    getParent();
                }
            });
        };
    };

    // Видалити категорію від faq
    const deleteCategory = (id) => {
        let categories = [];
        // Відфільтрувати категорію, яку потрібно видалити
        let filterCategories = catItem.filter(el => el.id !== id);
        // Наповнити масив з категорями, перетвеними у формат для API
        filterCategories.map(el => categories.push(`api/categories/${el.id}`));
        // Дані, які підуть із запитом
        let article = {
            category: categories
        };

        // Оновити категорії для faq
        AxiosInstance.put(`/faqs/${props.faq_id}`, article).then((response) => {
            // console.log("response put", response);
            if (response.status == 200) {
                // Popup про успішні зміни
                props.toastifySuccess(popupTextDeleteSuccess);
                getParent();
            }
        });
    };

    // Видалити категорію від faq
    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        // console.log("delete row id", row);
                        deleteCategory(row);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    return (
        <>
            <div className="row">
                <div className="col"><h3>Категорії</h3></div>
                <div className="col d-flex">
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        value={selectedOption}
                        onChange={handleChange}
                        className="flex-grow-1 me-2"
                    />
                    <CButton onClick={handleSave} className="mb-2" color="secondary">+</CButton>
                </div>
            </div>

            {/* Список з доданими категоріями */}
            {
                (catItem.length > 0) ?
                    <CListGroup className="mb-3">
                        {
                            catItem.map((v, k) => {
                                i++;
                                return (
                                    <CListGroupItem key={k} className="d-flex justify-content-between align-items-center">
                                        {/* <Link to={`/products/update/${v.id}`} >{`${i})`} {v.name}</Link> */}
                                        {`${i})`} {v.name}
                                        <CButton color="danger" variant="outline" onClick={() => { Deleted(v.id) }}>
                                            Видалити
                                        </CButton>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup>
                    :
                    <p className="ml-3">Категорій не знайдено</p>
            }
        </>
    );
};

export default FaqsCategories;