// Функція для заокруглення числа до 2-х знаків після коми
export const roundTheNumber = (number) => {
    let roundedNumber = 0;
    if (typeof number === 'string') {
        // console.log("type = string");
        roundedNumber = number;
    }
    // Перевірка на ціле число
    else if (number !== undefined && Number.isInteger(number)) {
        // console.log("Integer");
        roundedNumber = number.toString();
    }
    // Заокруглити до двох знаків після коми
    else if (number != undefined) {
        // console.log("toFixed(2)");
        roundedNumber = number.toFixed(2);
    }
    // console.log('roundedNumber', roundedNumber);
    return roundedNumber;
};