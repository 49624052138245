import React, { useState } from 'react';
import AxiosInstance from 'src/components/Axios';
import { confirmAlert } from 'react-confirm-alert'; // Import

// Вибрати головне зображення для продукту озміщеного на сайті
const SelectMainSiteProductsImg = (props) => {
  // console.log("DeleteImgFrom media", media);
  const urlApi = '/site_products';
  // Текст для popup
  const popupTextMediaSuccess = "Головне зображення вибрано";
  const popupTextError = "Помилка запиту";

  // Закрити форму з всіма зображеннями і відкрити форму підтвердження вибору головного зображення
  const openSelectForm = (id) => {
    // Закрити модальне вікно
    props.setShowModalMedia(false);
    // Змінити головне зображення продукта
    selectImg(id);
  };

  // Змінити головне зображення продукта
  function selectImg(id) {
    confirmAlert({
      title: 'Ви впевнені що хочите зробити вибране зображення головним?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            AxiosInstance.put(`${urlApi}/${props.dataState.id}`, { mainSiteProductMedia: `api/site_products_media/${id}` }).then((response) => {
              // console.log("mainSiteProductMedia put response", response);
              if (response.status === 200) {
                // Запит на оновлення даних (зображень) продукту
                props.setMainSiteProductMedia(response.data.mainSiteProductMedia);
                // Popup про успішні зміни
                props.toastifySuccess(popupTextMediaSuccess);
              } else {
                // console.log("Помилка запиту");
                props.toastifyWarning(popupTextError);
              }
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            // Відкрити модальне вікно
            props.setShowModalMedia(true);
          }
        }
      ]
    });
  };

  return (
    <>
      <div className='d-flex justify-content-center mt-3'>
        {
          (props.media != '') &&
          props.media.map(el => {
            return (
              <div key={el.id} className="me-2">
                <img
                  className="me-3"
                  src={(props.media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.media.contentUrl : ''}
                  style={{ height: '100px', width: 'auto', cursor: "pointer" }}
                  onClick={() => openSelectForm(el.id)}
                />
              </div>
            );
          })
        }
      </div>
    </>
  );
};

export default SelectMainSiteProductsImg;