import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';

// Функція для створення замовлення на основі preOrder
const user_data = JSON.parse(localStorage.getItem('user_data'));
const CreateOrderFromPreOrder = () => {


    const objOrderData = {
        account: '',
        client: '',
        //     agreement: '',
        // status: 'aproved',
        description: '',
        dateOrder: null,
        curency: 'uah',
        paymentMethod: 'cash',
        sumOrdered: 0,
        curencyRate: 1,
        discountTotal: 0,
        discountPercent: 0,
        // shippingAddressStreet: '',
        shippingAddressCity: '',
        // shippingAddressBuilding: '',
        // shippingAddressCountry: 'Україна',
        // shippingAddressDate: '',
        synced: false,
        manager: '',
        preOrder: '',
    };
    const urlPreOrdersApi = '/pre_orders';
    const urlApi = '/orders';
    const navigate = useNavigate();
    // Замовлення, яке буде створюватися з preOrders
    const [order, setOrder] = useState(false);
    // Клоновані дані
    const [values, setValues] = useState(objOrderData);
    const [isOpenWarningMessage, setIsOpenWarningMessage] = useState(false);
    const [typeMessage, setTypeMessage] = useState("");

    // Створити замовлення
    useEffect(() => {
        // console.log('values', values);
        if (order !== false) {
            // console.log("Зробити реалізацію");
            AxiosInstance.post(urlApi, values).then((response) => {
                if (response.status == 201) {
                    // alert("Замовлення створено");
                    // console.log("response post", response);
                    // Перейти на сторінку створеного замовлення
                    navigate(`/orders/update/${response.data.id}`);
                }
                else {
                    alert("Error");
                }
            });
        }
    }, [values]);

    // Наповнити дані нового замовлення
    useEffect(() => {

        if (order !== false) {
            // console.log('order', order);
            
            
            
            // if (order.account  != undefined && order.account.manager == undefined) {
                //     setTypeMessage("manager");
                //     setIsOpenWarningMessage(true);
                //     // alert(`Для контрагента ${order.account.name} не призначений менеджер. Для реалізації попереднього замовлення потрібно призначити менеджера.`);
                // } else if (order.agreement == undefined) {
                    //     // alert(`Для контрагента ${order.account.name} відсутній договір. Для реалізації попереднього замовлення потрібно скласти договір.`);
                    //     setTypeMessage("agreement");
                    //     setIsOpenWarningMessage(true);
                    // } else {
            AxiosInstance.get(`${urlPreOrdersApi}/${order.id}`, {params:{
                'g[0]': 'pre_order:read',
                'g[1]': 'pre_order:client'
            }}).then((response) => {
                
                let preOrder = response.data;
                        // Масив з доданими до замовлення товарами 
                const order_Products = preOrder.preOrderProducts.map((product) => {
                    // id для pre_order_products
                    let elId = product.id;
                    delete product['@id'];
                    delete product.id;
                    delete product['@type'];
                    delete product.acceptedProducts;
                    delete product.preOrder;
                    // delete product.loadInvoiceProducts;
                    return (
                        {
                            ...product,
                            measurementUnit: (product.measurementUnit != undefined) ? `/api/measurment_units/${product.measurementUnit.id}` : `/api/measurment_units/${product.product.measurement_unit.id}`,
                            product: `/api/products/${product.product.id}`,
                            preOrderProduct: `/api/pre_order_products/${elId}`
                        }
                    )
                });
                console.log(11111);
                console.log(user_data);
                console.log(preOrder);
                let newOrder = {
                    // account: `/api/accounts/${order.account.id}`,
                    client: `/api/users/${preOrder.client.id}`,
                    // agreement: (order.agreement != undefined) ? `/api/agreements/${order.agreement.id}` : '',
                    // agreement: `/api/agreements/${order.agreement.id}`,
                    status: "is_forming",
                    description: `Реалізовано з попереднього замовлення #${preOrder.id} ${preOrder.description}`,
                    // dateOrder: order.dateOrder,
                    curency: preOrder.preOrderProducts[0].curency,
                    paymentMethod: preOrder.paymentMethod,
                    sumOrdered: preOrder.sumOrdered,
                    // curencyRate: order.curencyRate,
                    // discountTotal: order.discountTotal,
                    // discountPercent: order.discountPercent,
                    // shippingAddressStreet: order.shippingAddressStreet,
                    shippingAddressCity: preOrder.shippingAddressCity, 
                    // shippingAddressBuilding: order.shippingAddressBuilding, 
                    // shippingAddressCountry: order.shippingAddressCountry,
                    // shippingAddressDate: order.shippingAddressDate,
                    synced: false,
                    // manager: `/api/users/${order.account.manager.id}`,
                    orderProducts: order_Products,
                    preOrder: `/api/pre_orders/${preOrder.id}`
                };
                console.log(1);
                if(preOrder.account != undefined && preOrder.account.id  != undefined)
                    newOrder['account'] = `/api/accounts/${preOrder.account.id}`;
                console.log(2);
                if(preOrder.agreement != undefined && preOrder.agreement.id  != undefined)
                    newOrder['agreement'] = `/api/agreements/${preOrder.agreement.id}`;
                
                console.log(3);
                if(preOrder.account != undefined && preOrder.account.manager  != undefined)
                    newOrder['manager'] = `/api/users/${preOrder.account.manager.id}`;
                else
                    newOrder['manager'] = `/api/users/${user_data.id}`;


                console.log(newOrder);

                setValues(newOrder);

            });
            // }
        }
    }, [order]);

    return {
        setOrder,
        isOpenWarningMessage,
        setIsOpenWarningMessage,
        typeMessage,
        setTypeMessage
    };
};

export default CreateOrderFromPreOrder;