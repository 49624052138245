import { useEffect, useState } from "react";
import AxiosInstance from "src/components/Axios";
import { DataGrid } from '@mui/x-data-grid';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import * as dayjs from 'dayjs';


const SellAaccountsManager = ({ filter, countMarkUp }) => {


    const [rows, setRows] = useState([]);
    const [accKeys, setAccKeys] = useState(1);
    useEffect(() => {
        AxiosInstance.get(`/statistics/get`, { params: filter }).then((response) => {
            // console.log("response LoaderAgreements", response);
            setRows(response.data)
            setAccKeys([...response.data.keys()])
            return response;
        });
    }, []);

    return (

        <>
            <h4>менеджер + контрагент:</h4>

            <CAccordion activeItemKey={accKeys}>
                {
                    rows.map((products, key) => {
                        // let rows = [];
                        // Object.keys(products).forEach(element => {
                        //     rows.push(products[element])
                        // });
                        return (
                            <CAccordionItem itemKey={key}>
                                <CAccordionHeader>{products[Object.keys(products)[0]].user_name}</CAccordionHeader>
                                <CAccordionBody>
                                    <CTable>
                                        <CTableHead>
                                            <CTableRow>
                                                {/* <CTableHeaderCell scope="col">#</CTableHeaderCell> */}
                                                <CTableHeaderCell scope="col">Контрагент</CTableHeaderCell>
                                                {/* <CTableHeaderCell scope="col">дата</CTableHeaderCell> */}
                                                <CTableHeaderCell scope="col">Факт відвантаж. шт.</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Факт відвантаж. грн</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Собівартість грн.</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Дохід</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">середня прихідна ціна</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">середня продажна ціна</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">середня націнка</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">націнка у відсотках</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody>
                                            {
                                                Object.keys(products).map( (element) => {
                                                    let d = countMarkUp(products[element].price_income,products[element].sum, products[element].count);
                                                    return (
                                                        <CTableRow key={element}>
                                                            <CTableHeaderCell>{products[element].account_name}</CTableHeaderCell>
                                                            {/* <CTableHeaderCell>{dayjs(products[element].date_packing.date).format('DD.MM.YYYY')}</CTableHeaderCell> */}
                                                            <CTableDataCell>{parseFloat(products[element].count).toFixed(3)}</CTableDataCell>
                                                            <CTableDataCell>{parseFloat(products[element].sum).toFixed(3)}</CTableDataCell>
                                                            <CTableDataCell>{parseFloat(products[element].price_income).toFixed(3)}</CTableDataCell>
                                                            <CTableDataCell>{parseFloat(d.sum_income).toFixed(3)}</CTableDataCell>
                                                            <CTableDataCell>{parseFloat(d.average_incoming_price).toFixed(3)}</CTableDataCell>
                                                            <CTableDataCell>{parseFloat(d.average_selling_price).toFixed(3)}</CTableDataCell>
                                                            <CTableDataCell>{parseFloat(d.average_markup).toFixed(3)}</CTableDataCell>
                                                            <CTableDataCell>{parseFloat(d.markup_in_percent).toFixed(3)}</CTableDataCell>
                                                            
                                                        </CTableRow>
                                                    )
                                                })
                                            }

                                        </CTableBody>
                                    </CTable>
                                </CAccordionBody>
                            </CAccordionItem>
                        )
                    })
                }
            </CAccordion>

        </>
    );

}

export default SellAaccountsManager;