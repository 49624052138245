import React, { useState, useEffect } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import CouponsCreateForm from 'src/components/coupons/CouponsCreateForm';
import CouponsCategories from 'src/components/coupons/CouponsCategories';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

const urlApi = '/coupons';

export async function LoaderCoponsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const CouponsForm = () => {
  const { data } = useLoaderData();
  const [MyData, setMyData] = useState(data);
  // Викликає popup з власного компонента
  const popup = MyToastify();

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // console.log("MyData", MyData);

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CouponsCreateForm data={MyData} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />
      <hr />
      {(MyData != undefined) ? <CouponsCategories coupon_id={MyData.id} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} /> : ''}
    </>
  );
};

export default CouponsForm;