import { useEffect, useState } from "react";
import AxiosInstance from "src/components/Axios";
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import DatePicker from "react-datepicker";

import SellProduct from "./types/SellProduct";
import SellProductAccounts from "./types/SellProductAccounts";
import SellProductUsers from "./types/SellProductUsers";
import SellManagers  from "./types/SellManagers";
import SellManagerProducts  from "./types/SellManagerProducts";
import SellManagerIncoming  from "./types/SellManagerIncoming";
import SellManagerIncomingProducts  from "./types/SellManagerIncomingProducts";
import SellAccounts  from "./types/SellAccounts";
import SellAccountsProducts  from "./types/SellAccountsProducts";
import SellAaccountsManager  from "./types/SellAaccountsManager";
import SellAccountsMannagerProducts  from "./types/SellAccountsMannagerProducts";


const Statistics = () => {

    const [filter, setFilter] = useState({
        dateStart: new Date(),
        dateEnd: new Date(),
        type: '',
    }); 
    const [defaultDate, setDefaultDate] = useState({
        dateStart: new Date(),
        dateEnd: new Date(),
    }); 

    useEffect(() => {
        let currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        let prevMonth = new Date(currentDate);
        setDefaultDate({...filter, dateStart: prevMonth })
        setFilter({...filter, dateStart: prevMonth.toISOString().slice(0, 10) })
        setFilter({...filter, dateEnd: currentDate.toISOString().slice(0, 10) })
    }, []);

    const datePicker = (value, name) => {
        let formattedDate = value.toISOString().slice(0, 10);
        setFilter({
            ...filter,
            [name]: formattedDate
        });
        setDefaultDate({
            ...defaultDate,
            [name]: value
        });
    }
    const countMarkUp = (income, sum, count) => {
        let rez = {
            'average_incoming_price': 0, // середня прихідна ціна
            'average_selling_price': 0, // середня продажна ціна
            'average_markup': 0, // середня націнка
            'markup_in_percent': 0, // націнка у відсотках
            'sum_income': 0, // Дохід
        }
        if (income > 0 && count > 0)
            rez['average_incoming_price'] = (income / count).toFixed(2);

        if (sum > 0 && count > 0)
            rez['average_selling_price'] = (sum / count).toFixed(2);

        rez['average_markup'] = (rez['average_selling_price'] - rez['average_incoming_price']).toFixed(2);

        if (rez['average_markup'] > 0 && rez['average_incoming_price'] > 0)
            rez['markup_in_percent'] = (rez['average_markup'] / rez['average_incoming_price']).toFixed() * 100;

        rez['sum_income'] = (sum - income).toFixed(2);

        return rez;
    }

    return(<>
        <div className="row">
            <div  className="col"><h3>Cтатистика продаж:</h3></div>
            <div  className="col d-flex">
                <div>
                    <label>Дата від:</label>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={defaultDate.dateStart}
                        onChange={(date) => datePicker(date, 'dateStart')}
                    />
                </div>
                <div >
                    <label>Дата до:</label>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={defaultDate.dateEnd}
                        onChange={(date) => datePicker(date, 'dateEnd')}
                    />
                </div>  
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="col">
                <ul>
                    <li><Link onClick={() => {setFilter({...filter, type: 'products'})}}><strong>Товари</strong></Link></li>
                    <li><Link onClick={() => {setFilter({...filter, type: 'products_accounts'})}}>товар + клієнт</Link></li>
                    <li><Link onClick={() => {setFilter({...filter, type: 'products_users'})}}>товар + менеджер</Link></li>
                </ul>
            </div>
            <div className="col">
                <ul>
                    <li><Link onClick={() => {setFilter({...filter, type: 'users_sum'})}}><strong>Менеджери</strong></Link></li>
                    <li><Link onClick={() => {setFilter({...filter, type: 'users'})}}>менеджер + товар</Link></li>
                    <li><Link onClick={() => {setFilter({...filter, type: 'users_incoming'})}}>менеджер + накладна</Link></li>
                    <li><Link onClick={() => {setFilter({...filter, type: 'users_incoming_products'})}}>менеджер + накладна + товар</Link></li>
                </ul>
            </div>
            <div className="col">
                <ul>
                    <li><Link onClick={() => {setFilter({...filter, type: 'accounts_sum'})}}><strong>Контрагенти</strong></Link></li>
                    <li><Link onClick={() => {setFilter({...filter, type: 'accounts'})}}>контрагент + товар</Link></li>
                    <li><Link onClick={() => {setFilter({...filter, type: 'users_accounts_sum'})}}>менеджер + контрагент</Link></li>
                    <li><Link onClick={() => {setFilter({...filter, type: 'users_accounts'})}}>менеджер + Контрагентами + товар</Link></li>
                </ul>
            </div>
        </div>
        <hr/>

        {(filter.type == 'products')&& <SellProduct filter={filter} countMarkUp={countMarkUp}/>}
        {(filter.type == 'products_accounts')&& <SellProductAccounts filter={filter} countMarkUp={countMarkUp}/>}
        {(filter.type == 'products_users')&& <SellProductUsers filter={filter} countMarkUp={countMarkUp}/>}

        {(filter.type == 'users_sum')&& <SellManagers filter={filter} countMarkUp={countMarkUp}/>}
        {(filter.type == 'users')&& <SellManagerProducts filter={filter} countMarkUp={countMarkUp}/>}
        {(filter.type == 'users_incoming')&& <SellManagerIncoming filter={filter} countMarkUp={countMarkUp}/>}
        {(filter.type == 'users_incoming_products')&& <SellManagerIncomingProducts filter={filter} countMarkUp={countMarkUp}/>}
        
        {(filter.type == 'accounts_sum')&& <SellAccounts filter={filter} countMarkUp={countMarkUp}/>}
        {(filter.type == 'accounts')&& <SellAccountsProducts filter={filter} countMarkUp={countMarkUp}/>}
        {(filter.type == 'users_accounts_sum')&& <SellAaccountsManager filter={filter} countMarkUp={countMarkUp}/>}
        {(filter.type == 'users_accounts')&& <SellAccountsMannagerProducts filter={filter} countMarkUp={countMarkUp}/>}



    
    </>);


}

export default Statistics;