import React, { useRef, useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import AccountsFormComponents from 'src/components/accounts/AccountsFormComponents';

const urlApi = '/accounts';
const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}`, 'accept': 'application/json' } };

export async function LoaderAccountsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`, header).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
};

const AccountsForm = () => {
  const { data } = useLoaderData();

  return (
    <AccountsFormComponents data={data} type="accountPage" />
  );

};

export default AccountsForm;
