import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import DeleteImgFromSiteProducts from 'src/components/media/DeleteImgFromSiteProducts';
import AxiosInstance from '../Axios';
import { CButton } from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert';
import SelectMainSiteProductsImg from '../media/SelectMainSiteProductsImg';

const SiteProductsMedia = (props) => {
    // console.log('SiteProductsMedia props', props);

    const urlApi_1 = '/site_products';
    const urlApi_2 = '/site_products_media';
    const popupTextError = "Помилка запиту";
    const popupTextDelete = "Зображення видалено";

    const [newMedia, setNewMedia] = useState(false);
    const [media, setMedia] = useState('');
    // Для модального вікна "Додати зображення"
    const [showModalMedia, setShowModalMedia] = useState(true);
    // Для модального вікна "Видалення зображення"
    const [showModalMediaDelete, setShowModalMediaDelete] = useState(true);
    // Чи показувати маленькі зображення та індикатори зображень (dotIndicator) для галереї
    const [isShowThumbs, setIsShowThumbs] = useState(false);
    // Для модального вікна "Головне зображення"
    const [showModalMediaSelectMain, setShowModalMediaSelectMain] = useState(true);
    // Головне зображення для продукта
    const [mainSiteProductMedia, setMainSiteProductMedia] = useState(props.dataState != undefined ? props.dataState.mainSiteProductMedia : false);
    // Індекс головного зображення з масиву media
    const [indexMainSiteProductMedia, setIndexMainSiteProductMedia] = useState(0);

    // Відслідковувати маcив з зображеннями
    useEffect(() => {
        if (props.dataState.siteProductsMedia != undefined && props.dataState.siteProductsMedia.length > 0) {
            setMedia(props.dataState.siteProductsMedia);
        } else {
            setMedia('');
        };
    }, [props.dataState]);

    // Відслідковувати показ маленьких зображень для галереї
    useEffect(() => {
        if (media != "" && media.length > 1) {
            setIsShowThumbs(true);
        } else {
            setIsShowThumbs(false);
        };
    }, [media]);

    // Відслідковувати додавання нового зображення
    useEffect(() => {
        if (newMedia != false && props.dataState.id != undefined) {
            // console.log('newMedia', newMedia);
            const article = {
                name: newMedia.name,
                siteProduct: `/api/site_products/${props.dataState.id}`,
                media: `/api/media_objects/${newMedia.id}`
            };
            // Додати зображення
            siteProductsMedia(article);
        }
    }, [newMedia, props.dataState]);

    // Функція для створення SiteProductsMedia
    const siteProductsMedia = (article) => {
        AxiosInstance.post(`${urlApi_2}`, article).then((response) => {
            // console.log("siteProductsedia post response", response);
            if (response.status == 201) {
                // console.log('newMedia', newMedia);
                // Обнулити нове зображення
                setNewMedia(false);
                // Оновити дані замовлення
                props.getData();
            } else {
                // console.log("Помилка запиту");
                props.toastifyWarning(popupTextError);
            };
        });
    };

    // Запит на видалення
    const deleteSiteProductsMedia = (id) => {
        AxiosInstance.delete(`${urlApi_2}/${id}`).then((response) => {
            // console.log("siteProductsedia delete response", response);
            if (response.status == 204) {
                props.toastifySuccess(popupTextDelete);
                // Оновити дані замовлення
                props.getData();
            } else {
                // console.log("Помилка запиту");
                props.toastifyWarning(popupTextError);
            };
        });
    };

    // Функція для видалення зображення
    const deleteImg = (id) => {
        // Якщо головне зображення визначено та його id співпадає з id для видалення
        if (mainSiteProductMedia != undefined && mainSiteProductMedia != null && mainSiteProductMedia != false && mainSiteProductMedia.id === id) {
            AxiosInstance.put(`${urlApi_1}/${props.dataState.id}`, { mainSiteProductMedia: null }).then((response) => {
                // console.log('site-products put response', response);
                if (response.status === 200) {
                    // Обнулити mainSiteProductMedia
                    setMainSiteProductMedia(false);
                    // Запит на видалення
                    deleteSiteProductsMedia(id);
                } else {
                    // console.log("Помилка запиту");
                    props.toastifyWarning(popupTextError);
                }
            })
        } else {
            // Запит на видалення
            deleteSiteProductsMedia(id);
        };
    };

    // Видалити зображення продукта
    function Deleted(id) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити зображення ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        deleteImg(id);
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    // Для модального вікна "Додати зображення"
    const actionShowModalMedia = (rez) => {
        setShowModalMedia(rez);
        // console.log(11111);
        // if (!rez)
        // UpdateMedia();
    };

    // Для модального вікна "Видалення зображення"
    const actionShowModalMediaDelete = (rez) => {
        setShowModalMediaDelete(rez);
        // console.log(11111);
        // if (!rez)
        // UpdateMedia();
    };

    // Для модального вікна "Головне зображення"
    const actionShowModalSelectMain = (rez) => {
        setShowModalMediaSelectMain(rez);
    };

    // Визначити індекс головного зображення з масиву media
    useEffect(() => {
        // console.log('media', media);
        // console.log('mainSiteProductMedia', mainSiteProductMedia);
        if (media != undefined && media.length > 0 && mainSiteProductMedia != undefined) {
            const index = media.findIndex(item => item.id === mainSiteProductMedia.id);
            // console.log('index', index);
            if (index !== -1) {
                setIndexMainSiteProductMedia(index);
            } else {
                setIndexMainSiteProductMedia(0);
            }
        }
    }, [media, mainSiteProductMedia]);

    return (
        <>
            <div className="row mb-3">
                <div className="col-12 d-flex my-3">
                    <Carousel
                        className="me-3"
                        style={{ height: '200px', width: 'auto' }}
                        showArrows={false}
                        showThumbs={isShowThumbs}
                        showIndicators={isShowThumbs}
                        width={300}
                        showStatus={false}
                        thumbWidth={80}
                        selectedItem={indexMainSiteProductMedia}
                    >
                        {
                            // Відобразити всі зображення до продукту
                            (media != '') &&
                            media.map(el => {
                                return (
                                    <div key={el.id}>
                                        <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.media.contentUrl : ''} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>

                    <div>
                        <div className="mb-3">
                            <BasicModal
                                show={showModalMedia}
                                actionShowModal={actionShowModalMedia}
                                title={`Додати зображення товара`}
                                btn_name='Додати зображення'
                                content={
                                    <MediaFrom siteProduct_id={props.dataState.id}
                                        actionShowModal={actionShowModalMedia}
                                        toastifySuccess={props.toastifySuccess}
                                        type="siteProducts" setNewMedia={setNewMedia}
                                    />
                                }
                            />
                        </div>

                        {
                            (media != "") &&
                            <>
                                {
                                    (media.length === 1) ?
                                        <CButton color="secondary" onClick={() => Deleted(props.dataState.siteProductsMedia[0].id)}>Видалити зображення</CButton>
                                        :

                                        <div className="mb-3">
                                            {media.length > 0 &&
                                                <BasicModal
                                                    show={showModalMediaDelete}
                                                    actionShowModal={actionShowModalMediaDelete}
                                                    title={`Видалити зображення товара`}
                                                    btn_name='Видалити зображення'
                                                    content={
                                                        <DeleteImgFromSiteProducts
                                                            setShowModalMedia={actionShowModalMediaDelete}
                                                            media={media}
                                                            Deleted={Deleted}
                                                        />
                                                    }
                                                />
                                            }
                                        </div>
                                }

                                <div className="mb-3">
                                    {media.length > 1
                                        &&
                                        <>
                                            <BasicModal
                                                show={showModalMediaSelectMain}
                                                actionShowModal={actionShowModalSelectMain}
                                                title={`Виберіть головне зображення для товара`}
                                                btn_name='Головне зображення'
                                                content={
                                                    <SelectMainSiteProductsImg
                                                        dataState={props.dataState}
                                                        media={media}
                                                        setMainSiteProductMedia={setMainSiteProductMedia}
                                                        setShowModalMedia={actionShowModalSelectMain}
                                                        toastifySuccess={props.toastifySuccess}
                                                        toastifyWarning={props.toastifyWarning}
                                                    />
                                                }
                                            />
                                            <span>
                                                {
                                                    (mainSiteProductMedia != false && mainSiteProductMedia != null) ?
                                                        <span style={{ color: "green" }}>Головне зображення визначено</span>
                                                        :
                                                        <span style={{ color: "red" }}>Головне зображення не визначено</span>
                                                }
                                            </span>
                                        </>
                                    }
                                </div>

                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

export default SiteProductsMedia;