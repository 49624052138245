import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import AxiosInstance from 'src/components/Axios';

// Функція для дублювання замовлення
const CopyOrder = () => {

    const objOrderData = {
        account: '',
        agreement: '',
        status: 'aproved',
        description: '',
        dateOrder: null,
        curency: 'uah',
        paymentMethod: 'cash',
        // sumOrdered: 0,
        curencyRate: 1,
        discountTotal: 0,
        discountPercent: 0,
        // shippingAddressStreet: '',
        shippingAddressCity: '',
        // shippingAddressBuilding: '',
        // shippingAddressCountry: 'Україна',
        // shippingAddressDate: '',
        synced: false,
        manager: ''
    };
    const urlApi = '/orders';
    // Оновити список з замовленнями (файл Ordes.js)
    const [updateOrdersList, setUpdateOrdersList] = useState(false);
    // Замовлення, яке буде дублюватися
    const [order, setOrder] = useState(false);
    // Клоновані дані
    const [values, setValues] = useState(objOrderData);

    // Створити замовлення
    useEffect(() => {
        // console.log('values', values);
        if (order !== false) {
            AxiosInstance.post(urlApi, values).then((response) => {
                if (response.status == 201) {
                    // console.log("response post", response);
                    // Обнулити дані для дублювання замовлення
                    setOrder(false);
                    // Оновити список з замовленнями "orders/"
                    setUpdateOrdersList(true);
                };
            });
        }
    }, [values]);

    // Наповнити дані нового замовлення
    useEffect(() => {
        if (order !== false) {
            // console.log('order', order);

            // Масив з доданими до замовлення товарами 
            const order_Products = order.orderProducts.map((product) => {
                delete product['@id'];
                delete product.id;
                delete product['@type'];
                delete product.acceptedProducts;
                delete product.loadInvoiceProducts;
                return (
                    {
                        ...product,
                        measurementUnit: `/api/measurment_units/${product.measurementUnit.id}`,
                        product: `/api/products/${product.product.id}`
                    }
                )
            });

            setValues({
                ...values,
                account: `/api/accounts/${order.account.id}`,
                agreement: (order.agreement != undefined) ? `/api/agreements/${order.agreement.id}` : '',
                status: order.status,
                description: `copy#${order.id} ${order.description}`,
                // dateOrder: order.dateOrder,
                curency: order.curency,
                paymentMethod: order.paymentMethod,
                sumOrdered: order.sumOrdered,
                curencyRate: order.curencyRate,
                discountTotal: order.discountTotal,
                // discountPercent: order.discountPercent,
                // shippingAddressStreet: order.shippingAddressStreet,
                shippingAddressCity: order.shippingAddressCity,
                // shippingAddressBuilding: order.shippingAddressBuilding,
                // shippingAddressCountry: order.shippingAddressCountry,
                shippingAddressDate: order.shippingAddressDate,
                synced: false,
                manager: (order.manager.id != undefined) ? `/api/users/${order.manager.id}` : '',
                orderProducts: order_Products
            });
        }
    }, [order]);

    return {
        updateOrdersList,
        setUpdateOrdersList,
        setOrder,
    };
};

export default CopyOrder;