import React, { useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import AxiosInstance from '../Axios';

const EditProductConfiguration = ({ configuration, actionShowModal, toastifySuccess, toastifyWarning }) => {

    const urlApi = "/product_infos";
    // Текст для popup
    const popupTextSuccess = "Комплектація до товару оновлена";
    const popupTextWarning = "Комплектація до товару не оновлена";

    const [values, setValues] = useState({
        name: configuration.name,
        sort: parseInt(configuration.sort),
    });

    // const [values, setValues] = useState({
    //     product: `/api/products/${productId}`,
    //     name: "",
    //     // value: "",
    //     // keyName: "",
    //     sort: 0
    // });

    const handleChange = (value, name) => {
        // console.log('handleChange value', value);

        if (name === "sort") {
            const newValue = (value === "") ? "" : parseFloat(value.replace(/\D/g, '')); // Пропускати тільки цифри
            setValues({
                ...values,
                [name]: newValue
            });
        } else {
            setValues({
                ...values,
                [name]: value
            });
        };
    };

    const checkSort = () => {
        if (values.sort != "") return;

        if (values.sort == "") {
            setValues({
                ...values,
                ["sort"]: 0
            });
        }
    };

    // Функція для оновлення комплектації
    const putComplectation = async () => {
        try {
            const response = await AxiosInstance.put(`${urlApi}/${configuration.id}`, values);
            // console.log('product_infos/ put response', response);
            // Popup про успішні зміни
            toastifySuccess(popupTextSuccess);
            // return actionShowModal(false);
            // actionShowModal(false - закрити модальне вікно, true - оновити);
            actionShowModal(false, true);
        } catch (error) {
            // Popup про попередження про неуспішне додавання інформації
            toastifyWarning(popupTextWarning);
            console.error("Помилка запиту оновленні комплектації: ", error);
        };
    };

    const handleSubmit = event => {
        event.preventDefault();
        let v = true;
        let vError = '';
        // console.log(values);
        if (values.name == '') {
            v = false;
            vError += "-> Назва\n";
        }
        // if (values.value == '') {
        //     v = false;
        //     vError += "-> Значення\n";
        // }
        if (values.sort === 0) {
            v = false;
            vError += "-> Сортування\n";
        }

        if (!v) {
            toastifyWarning("Ви не заповнили обовязкові поля:\n" + vError);
        } else {
            putComplectation();
        };
    };

    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-8'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={values.name} onChange={(e) => handleChange(e.target.value, 'name')} />
                        </div>
                    </div>

                    <div className='col-4'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={values.sort}
                                onChange={(e) => handleChange(e.target.value, 'sort')}
                                onBlur={(e) => checkSort()}
                            />
                        </div>
                    </div>
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    )
};

export default EditProductConfiguration;