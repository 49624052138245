import React, { useState, useEffect } from 'react';
import { CBadge, CListGroup, CListGroupItem, CButton, CCol, CRow, CFormCheck } from '@coreui/react';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import ProductAttributes from '../product/ProductAttributes';
import AddedAttributesAndVariations from './AddedAttributesAndVariations';

// Компонент доданого товару до товару, який відобжається на сайті
const ProductItemForSiteProducts = (props) => {
    // console.log('props ProductItemForSiteProducts', props);
    // console.log('props.product', props.product);

    const urlRouter = '/products/read';

    const media = (props.product.media != undefined && props.product.media.length > 0) ?
        process.env.REACT_APP_SERVER_URL + '' + (
            (props.product.mainMedia != undefined && props.product.mainMedia != null) ? props.product.mainMedia.contentUrl : props.product.media[0].contentUrl
        )
        :
        '';

    // Чи товар продається
    const showProduct = (props.product.show != undefined) ? props.product.show : false;
    const style = { color: !showProduct ? "red" : "" };

    return (
        <>
            <Accordion.Item eventKey={props.index}>
                <Accordion.Header>
                    {/* Порядковий номер */}
                    <span className='me-2'>{`${props.i})`}</span>

                    {
                        (media != "") ?
                            <img src={media} className="me-2" style={{ height: '45px', width: '45px' }} />
                            :
                            // Коли в товара немає зображення, то потрібний порожній простір. Через Row, Col неробив, оскільки великі відступи між колонками. Звичайний width не працює, бо CListGroupItem його ігнорує.
                            <span className="me-2" style={{ maxWidth: '45px', color: "transparent" }}>................</span>
                    }

                    <span style={style}>{props.product.name}</span>
                    {/* Додані атрибути та варіації до продукту */}
                    <span className="ms-2" style={{ color: "grey", fontSize: "13px" }}>
                        <AddedAttributesAndVariations product={props.product} />
                    </span>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="me-2 my-2" style={{ textAlign: "right" }}>
                        <Link to={`${urlRouter}/${props.product.id}`}>
                            <CButton className="me-2" type="submit" color="secondary">
                                Перейти на товар
                            </CButton>
                        </Link>
                        <CButton className='ms-auto' color="danger" variant="outline" onClick={() => { props.Deleted(props.product.id) }}>
                            Видалити
                        </CButton>
                    </div>

                    <div className="ms-4" style={{ width: "85%", }}>
                        <ProductAttributes productId={props.product.id} attributeItems={props.product.attributeItems} toastifySuccess={props.toastifySuccess} type="site-products" getData={props.getData} />
                    </div>
                </Accordion.Body>
            </Accordion.Item >
        </>
    );
};

export default ProductItemForSiteProducts;