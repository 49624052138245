import React from 'react';
import { Modal, StyledEngineProvider, Typography, Box } from '@mui/material';
import { CButton } from '@coreui/react';

// Попередження при редагуванні товару
const WarningDontHaveManager = (props) => {
    
    // Стилі для модального вікна
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 1,
    };

    return (
        <StyledEngineProvider injectFirst>
            <Modal
                open={props.openWarning}
                onClose={props.handleCloseWarning}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ padding: "10px", textAlign: "center" }}>
                            {
                                props.typeMessage === "agreement" &&
                                <p>Для контрагента {props.accountForModal.name} відсутній договір. Для реалізації попереднього замовлення потрібно скласти договір.</p>
                            }
                            {
                                props.typeMessage === "manager" &&
                                <p>Для контрагента {props.accountForModal.name} не призначений менеджер. Для реалізації попереднього замовлення потрібно призначити менеджера.</p>
                            }
                            <CButton color="secondary" onClick={() => props.handleCloseWarning()}>Добре</CButton>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </StyledEngineProvider>
    );
};

export default WarningDontHaveManager;