import React, { useEffect, useRef, useState } from 'react';
import AddProductConfiguration from './AddProductConfiguration';
import BasicModal from 'src/components/Modal';
import MyToastify from '../myComponents/MyToactify';
import ViewProductConfiguration from './ViewProductConfiguration';
import AxiosInstance from '../Axios';

// Вкладка "Комплектація"
const ProductConfiguration = (props) => {
    // console.log('ProductConfiguration props', props);

    const urlApi = "/product_infos";

    // Викликає popup з власного компонента
    const popup = MyToastify()

    const [productComplectations, setProductConfigurations] = useState(props?.productInfos ?? []);
    const [showModal, setShowModal] = useState(false);

    // Функція для отримання даних комплектації після оновлення
    const getComplectations = async () => {
        try {
            const response = await AxiosInstance.get(`${urlApi}?product.id=${props.productId}`);
            // console.log('product_infos get response', response);
            setProductConfigurations(response.data["hydra:member"]);
        } catch (error) {
            console.error("Помилка запиту за комплектацією: ", error);
        };
    };

    const actionShowModal = (rez, isUpdate = false) => {
        setShowModal(rez);
        if (!rez && isUpdate) {
            getComplectations();
        };
    };

    return (
        <div>
            <div className='row'>
                <div className='col'><h3>Комплектація</h3></div>
                <div className='col d-flex justify-content-end mb-2'>
                    <BasicModal
                        show={showModal}
                        actionShowModal={actionShowModal}
                        title={`Додати комплектацію до товару`}
                        content={
                            <AddProductConfiguration
                                actionShowModal={actionShowModal}
                                productId={props.productId}
                                toastifySuccess={popup.toastifySuccess}
                                toastifyWarning={popup.toastifyWarning}
                            />
                        }
                    />
                </div>
            </div>

            <ViewProductConfiguration
                productConfigurations={productComplectations}
                getComplectations={getComplectations}
                popup={popup}
                toastifySuccess={popup.toastifySuccess}
                toastifyWarning={popup.toastifyWarning}
            />
        </div>
    );
};

export default ProductConfiguration;