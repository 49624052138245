import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

const urlApi = '/attributes';
const urlRouter = '/attributes';

const page = 1;
const itemsPerPage = 30;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderAttributes({ params }) {
  let res = await AxiosInstance.get(`${urlApi}?main=true&page=${page}`).then((response) => {
    return response;
  });
  return res;
}

// Атрибути
const Attributes = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [clear, setClear] = useState(false);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Атрибут видалено";

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  }
  const getRowsTable = (p = 1) => {
    setShowLoader(true);
    setClear(false);
    let params = {
      itemsPerPage: itemsPerPage,
      page: p,
    };

    if (nameFilterSearch != '') {
      params['name'] = nameFilterSearch;
    };

    AxiosInstance.get(urlApi, { params: params }).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
      setShowLoader(false);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  }

  // Відслідковувати чи блокувати кнопку фільтрації
  useEffect(() => {
    if (nameFilterSearch != "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  }, [nameFilterSearch]);

  // Після скидання фільтра зробити запит за всіма товарами
  useEffect(() => {
    if (nameFilterSearch === "" && clear) {
      getRowsTable();
    };
  }, [nameFilterSearch, clear]);

  const handleNameFilterChange = (event) => {
    setNameFilterSearch(event.target.value);
  };

  // Фільтрування по name, code1c, barcode, артиклу та "товар продається"
  const handleFilterSearch = (event) => {
    // console.log("handleFilterSearch event", event)
    event.preventDefault();
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setClear(true);
    setShowLoader(true);
    setNameFilterSearch("");
    // setDisabled(true);
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              // Popup про успішні зміни
              popup.toastifySuccess(popupTextSuccess);
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'name', headerName: 'Назва', width: 200 },
    { field: 'slug', headerName: 'Синонім', width: 400 },
    { field: 'sort', headerName: 'Сортування', width: 100 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        // return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline">Переглянути</CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      width: 90,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CAccordion flush>
              <CAccordionItem>
                <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                <CAccordionBody>
                  <CRow className="my-3 mx-2">
                    <CCol md={9} className="me-auto">
                      <CRow>
                        <CCol md={3} className='filter-field'>
                          <CFormInput
                            placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                            onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol md={3} style={{ textAlign: "end" }}>
                      <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                      <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>

            <CCardHeader>
              <strong>Атрибути</strong> <small></small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
              </p>
              <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={itemsPerPage}
                  rowsPerPageOptions={[itemsPerPage]}
                  pagination
                  rowCount={rowCountState}
                  loading={showLoader}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Attributes;
