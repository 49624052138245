/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";
import { confirmAlert } from 'react-confirm-alert';
import { CBadge, CListGroup, CListGroupItem, CButton, CIcon } from '@coreui/react';
import EditProductInfo from 'src/components/product/EditProductInfo';
import BasicModal from 'src/components/Modal';
import { sortObj } from "../store/sortObj";
import { PropaneSharp } from "@mui/icons-material";
import EditProductConfiguration from "./EditProductConfiguration";

const urlApi = '/product_infos';

const ViewProductConfiguration = (props) => {
    // console.log('ViewProductConfiguration props', props);

    // console.log('productInfos', product);
    // const [productInfo, setProductInfo] = useState(props?.productInfos ?? []);
    const [productConfigurations, setProductConfigurations] = useState(props?.productConfigurations ?? []);
    useEffect(() => {
        // console.log('productConfigurations', productConfigurations);
    }, [productConfigurations]);

    useEffect(() => {
        if (props?.productConfigurations.length > 0) {
            setProductConfigurations(props?.productConfigurations);
        } else {
            setProductConfigurations([]);
        };
    }, [props?.productConfigurations]);

    const [showModal, setShowModal] = useState(false);

    // Текст для popup
    const popupTextSuccess = "Інформація про товар видалена";

    const actionShowModal = (rez, isUpdate = false) => {
        setShowModal(rez);
        if (!rez && isUpdate) {
            props.getComplectations();
        };
    };

    // Функціядля видалення комплектації з товару
    const deleteConfiguration = async (id) => {
        try {
            const response = await AxiosInstance.delete(`${urlApi}/${id}`);
            // console.log('product_infos delete response', response);
            // Popup про успішні зміни
            props.popup.toastifySuccess(popupTextSuccess);
            props.getComplectations();
        } catch (error) {
            console.error("Помилка запиту на видалення комплектації: ", error);
        };
    };

    function Deleted(row) {
        confirmAlert({
            title: `Ви впевнені що хочите видалити цей пункт ("${row.name}") ?`,
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        deleteConfiguration(row.id)
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    return (
        <>
            {
                (productConfigurations.length > 0) ?
                    <CListGroup>
                        {
                            sortObj(productConfigurations, 'sort').map((configuration) => {
                                return (
                                    <CListGroupItem key={configuration.id} className="d-flex justify-content-between align-items-center">
                                        <div>{configuration.sort}) <strong>{configuration.name}</strong> (№ сортування - {configuration.sort})</div>
                                        <div className="edit-inner">
                                            <BasicModal
                                                btn_name={'Редагувати'}
                                                show={showModal}
                                                actionShowModal={actionShowModal}
                                                title={`Редагувати інформацію`}
                                                content={
                                                    <EditProductConfiguration
                                                        actionShowModal={actionShowModal}
                                                        configuration={configuration}
                                                        toastifySuccess={props.popup.toastifySuccess}
                                                        toastifyWarning={props.popup.toastifyWarning}
                                                    />
                                                } />
                                            <CButton color="danger" variant="outline" onClick={() => { Deleted(configuration) }} >
                                                Видалити
                                            </CButton>
                                        </div>
                                    </CListGroupItem>
                                )
                            })
                        }
                    </CListGroup >
                    :
                    <p className="ml-3">Комплектації не знайдено</p>
            }
        </>
    )
};

export default ViewProductConfiguration;