import React, { useEffect, useState } from 'react';
import AxiosInstance from '../../Axios';
import AsyncSelect from 'react-select/async';
import { CButtonGroup, CCardBody, CCardHeader, CCol, CRow, CButton, CForm, CInputGroup, CFormLabel, CFormInput, CFormCheck, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import ChooseProductTable from './ChooseProductTable';
import { useDispatch, useSelector } from 'react-redux';
import FilterProduct from 'src/components/filter/FilterProduct';



// Вибрати товар зі списку
const ChooseProduct = (props) => {

    const urlApi = '/products';
    const page = 1;
    const itemsPerPage = 100;
    const userData = localStorage.getItem('user_data');
    const [rows, setRows] = useState(false);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [categoryFilterSearch, setCategoryFilterSearch] = useState(''); // id вибраної категорії для запиту
    const [nameFilterSearch, setNameFilterSearch] = useState('');
    const [code1cFilterSearch, setCode1cFilterSearch] = useState('');
    const [articleFilterSearch, setArticleFilterSearch] = useState('');
    const [barcodeFilterSearch, setBarcodeFilterSearch] = useState('');
    // Заблокувати кнопку фільрації якщо не введені символи для пошуку
    const [disabled, setDisabled] = useState(true);
    const [specPrice, setSpecPrice] = useState(false);
    const [reserve, setReserve] = useState(false);
    const [accountProduct, setAccountProduct] = useState(false);
    const [balance, setBalance] = useState(true);
    // Вибрана категорія
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [user, setUser] = useState(null);
    const [product, setProduct] = useState(null);
    const [productClear, setProductClear] = useState(false);
    const [typeFilter, setTypeFilter] = useState('width');
    const options = useSelector(state => state.options);

    const [isAccordionOpen, setIsAccordionOpen] = useState(true); // відслідковувати чи акордион відкритияй чи закритий
    const styleAccordionIsOpen = { height: "390px", width: '100%', overflow: "auto" };
    const styleAccordionIsClose = { height: "520px", width: '100%', overflow: "auto" };
    const [styleContainer, setStyleContainer] = useState(styleAccordionIsOpen); // стилі для висоти блоку і врезультаті зміна висоти модального вікна



    // Завантажити товари при старті сторінки
    useEffect(() => {
        getRowsTable();
    }, [specPrice, reserve, accountProduct, balance]);

    useEffect(() => {
        let tf = localStorage.getItem('product_type_filter');
        if(tf != undefined){
            setTypeFilter(tf);
        }
    }, []);

    useEffect(() => {
        if (userData != null) {
            setUser(JSON.parse(userData));
        }
    }, [userData]);

    // const specPrices = useSelector(state => state.products.prices);

    // Запит за продуктами (+фільтр)
    const getRowsTable = (p = 1) => {
        // console.log(user.id);
        // console.log(reserve);
        let request = {
            page: p,
            itemsPerPage: itemsPerPage,
            "g[0]": "product:read",
            "g[1]": "product:admin",
            "g[2]": "product:productBalanceInStorages",
            "g[3]": "product:storageElement",
        };

        if(product != null){
            request['id'] = product.value;
        }else{
            if (balance)
                request['balance[gt]'] = 0;

            if (categoryFilterSearch != '')
                request['category.id'] = categoryFilterSearch;
            if (nameFilterSearch != '')
                request['name'] = nameFilterSearch;
            if (code1cFilterSearch != '')
                request['code1c'] = code1cFilterSearch;
            if (barcodeFilterSearch != '')
                request['barcode'] = barcodeFilterSearch;
            if (articleFilterSearch != '')
                request['article'] = articleFilterSearch;
        }
        if (specPrice === true && props !== undefined && props.order !== undefined && props.order.agreement !== undefined && props.order.account !== undefined) {
            let specification_type = options.filter((word) => word.option_key === 'specification_type');
            if (specification_type.length > 0) {
                if (specification_type[0].value == 'virtual_agreement') {
                    request['prices.agreement.virtual'] = true;
                    request['prices.agreement.account.id'] = props.order.account.id;
                } else {
                    request['prices.agreement'] = props.order.agreement.id;
                }
            } else {
                request['prices.agreement'] = props.order.agreement.id;
            }

        }
        if (reserve === true && user != null && user.id != undefined)
            request['productBalanceInStorages.manager.id'] = user.id;
        if (accountProduct === true && props !== undefined && props.order !== undefined && props.order.account !== undefined)
            request['orderProducts.order.account.id'] = props.order.account.id;

        // console.log("request", request);
        AxiosInstance.get(urlApi, { params: request }).then((response) => {
            setRows(response.data['hydra:member']);
            setRowCountState(response.data['hydra:totalItems']);
        });
    };

    // // Пагінація, наступна сторінка з товарами
    // const handlePageChange = (page) => {
    //     getRowsTable(page + 1);
    // };

    // Завантажити категорії
    const loadCategories = () => {
        return AxiosInstance.get('/categories', { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };

    // Вибрана категорія для відображення в полі пошуку та подальшій передачі для фільтревання товарів
    useEffect(() => {
        if (selectedCategory != null) {
            // console.log("selectedCategory", selectedCategory);
            setCategoryFilterSearch(selectedCategory.value);
        };
    }, [selectedCategory]);

    // Після вибору категорії зробити запит за товарами категорії та оновити товари для вибору
    useEffect(() => {
        getRowsTable();
    }, [categoryFilterSearch]);

    // Функція для вибору категорії з списку
    const handleChange = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
    };

    // Заблокувати кнопку фільрації якщо не введені символи для пошуку
    const disabledButton = (event) => {
        if (event.target.value) {
            setDisabled(false);
        } else {
            setDisabled(true);
        };
    };

    // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
    useEffect(() => {
        if (categoryFilterSearch != "" || nameFilterSearch != "" || code1cFilterSearch != "" || barcodeFilterSearch != "" || articleFilterSearch) {
            setDisabled(false);
        };
    }, [categoryFilterSearch, nameFilterSearch, code1cFilterSearch, barcodeFilterSearch, articleFilterSearch]);

    // ----- Start handleFilterChange -----
    const handleNameFilterChange = (event) => {
        setNameFilterSearch(event.target.value);
        disabledButton(event);
    };

    const handleCode1cFilterChange = (event) => {
        setCode1cFilterSearch(event.target.value);
        disabledButton(event);
    }

    const handleBarcodeFilterChange = (event) => {
        setBarcodeFilterSearch(event.target.value);
        disabledButton(event);
    };

    const handleArticleFilterChange = (event) => {
        setArticleFilterSearch(event.target.value);
        disabledButton(event);
    };
    // ----- End handleFilterChange -----

    // Фільтрування по категорії, name, code1c, barcode та article
    const handleFilterSearch = (event) => {
        // console.log("handleFilterSearch event", event)
        event.preventDefault();
        getRowsTable();
    };
    // Скинути фільтр
    const handleFilterReset = () => {
        setProduct(null);
        setProductClear(true);
        setCategoryFilterSearch("");
        setSelectedCategory(null);
        setNameFilterSearch("");
        setCode1cFilterSearch("");
        setBarcodeFilterSearch("");
        setArticleFilterSearch("");
        setDisabled(true);
        getRowsTable();
    };

    // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
    const keyDown = (event) => {
        // Виконати пошук
        if (event.key === 'Enter' && !disabled) {
            event.preventDefault();
            handleFilterSearch(event);
        };
        // Скинути пошук
        if (event.key === 'Escape') {
            event.preventDefault();
            handleFilterReset(event);
        };
    };
    // console.log('specPrices', specPrices);

    // Функція-перемикач значення для відслідковування чи акордион відкритияй чи закритий
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    // Відслідковувати чи акордион відкитий чи закритий для зміни стилів (в результаті висота модального вікна)
    useEffect(() => {
        isAccordionOpen ? setStyleContainer(styleAccordionIsOpen) : setStyleContainer(styleAccordionIsClose);
    }, [isAccordionOpen]);


    useEffect(() => {
        getRowsTable();
    }, [product]);
    

    const FtypeFilter = (val) => {
        setTypeFilter(val);
        localStorage.setItem('product_type_filter', val);
    }


    return (
        <>
            <CRow>
                <CCol xs={6}>
                    <h2 className="my-3 mx-2 orders-tableHeader">Оберіть потрібний товар</h2>
                </CCol>
                <CCol xs={6} className='d-flex align-items-center justify-content-end'>
                    <CButtonGroup role="group" aria-label="Виберіть фільтр">
                        <CButton color="secondary" variant="outline" active={(typeFilter == 'width')?true:false} onClick={()=>{FtypeFilter('width')}}>Розширений</CButton>
                        <CButton color="secondary" variant="outline" active={(typeFilter == 'speed')?true:false} onClick={()=>{FtypeFilter('speed')}}>Швидкий</CButton>
                    </CButtonGroup>
                </CCol>
            </CRow> 
            <CRow>
                <CCol xs={12}>
                {(typeFilter == 'speed')
                     ?
                     <>
                        <CRow className="mb-3 mx-2">
                            <CCol sm={8}>
                                <FilterProduct spec={true} setData={setProduct} clear={productClear} />
                            </CCol>
                            <CCol xxl={3} lg={4} md={6} sm={4} style={{ textAlign: "end" }} >
                                <CButton className="me-1" type="submit" color="primary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                                <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                            </CCol>
                        </CRow>
                     </>
                    :
                    <>
                    <CAccordion flush className="mb-3 orders-CAccordion" activeItemKey={1} onClick={handleAccordionToggle}>
                        <CAccordionItem itemKey={1}>
                            <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                            <CAccordionBody className="">
                                <div className="pt-2">
                                    <CRow className="mb-2 mx-2">
                                        <CCol sm={6}>
                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadCategories}
                                                defaultOptions
                                                value={selectedCategory}
                                                onChange={handleChange}
                                                className="flex-grow-1 me-1 mb-2 orders-filterSize"
                                                onKeyDown={keyDown}
                                                placeholder="Оберіть категорію"
                                            />
                                        </CCol>
                                        <CCol sm={6}>
                                            <CFormInput
                                                placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-1 mb-2 orders-filterSize"
                                                onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                                            />
                                        </CCol>
                                        {/* <CCol xxl={3} lg={4} md={3} sm={4}>
                                <CFormInput placeholder="code1c" id="code1cFilterSearch" aria-label="Пошук" className="me-1 mb-3" onChange={handleCode1cFilterChange} value={code1cFilterSearch} onKeyDown={keyDown} autoComplete="off" />
                            </CCol> */}
                                        <CCol xxl={3} lg={4} md={3} sm={4}>
                                            <CFormInput
                                                placeholder="Артикул" id="articleFilterSearch" aria-label="Пошук" className="me-1 mb-2 orders-filterSize"
                                                onChange={handleArticleFilterChange} value={articleFilterSearch} onKeyDown={keyDown} autoComplete="off"
                                            />
                                        </CCol>
                                        <CCol xxl={3} lg={4} md={3} sm={4}>
                                            <CFormInput
                                                placeholder="Штрих-код" id="barcodeFilterSearch" aria-label="Пошук" className="me-1 mb-2 orders-filterSize"
                                                onChange={handleBarcodeFilterChange} value={barcodeFilterSearch} onKeyDown={keyDown} autoComplete="off"
                                            />
                                        </CCol>
                                        <CCol xxl={3} lg={4} md={3} sm={4}>
                                            <CFormCheck
                                                className="me-1 mb-2 orders-filterSize" label="специфікація" defaultChecked={specPrice}
                                                onChange={() => { setSpecPrice(!specPrice) }}
                                            />
                                        </CCol>
                                        <CCol xxl={3} lg={4} md={6} sm={4} style={{ textAlign: "end" }}>
                                            <CButton className="me-1 orders-filterSize" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                                            <CButton className="orders-filterSize" type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow className="mb-1 mx-2">
                                        <CCol xxl={3} lg={4} md={3} sm={4}>
                                            <CFormCheck
                                                className="me-1 mb-1 orders-filterSize" label="Товари клієнта" defaultChecked={accountProduct}
                                                onChange={() => { setAccountProduct(!accountProduct) }}
                                            />
                                        </CCol>
                                        <CCol xxl={3} lg={4} md={3} sm={4}>
                                            <CFormCheck
                                                className="me-1 mb-1 orders-filterSize" label="Мій резерв" defaultChecked={reserve}
                                                onChange={() => { setReserve(!reserve) }}
                                            />
                                        </CCol>
                                        <CCol xxl={3} lg={4} md={3} sm={4}>
                                            <CFormCheck
                                                className="me-1 mb-1 orders-filterSize" label="Товар на балансі" defaultChecked={balance}
                                                onChange={() => { setBalance(!balance) }}
                                            />
                                        </CCol>
                                    </CRow>
                                </div >
                            </CAccordionBody >
                        </CAccordionItem >
                    </CAccordion >
                </>
                }

                    <div
                        // style={{ height: "400px", width: '100%', overflow: "auto" }}
                        style={styleContainer}
                    >
                        <ChooseProductTable rows={rows} setSelectedProductId={props.setSelectedProductId} addToOrder={props.addToOrder} products={props.products} />
                    </div>
                </CCol >
            </CRow >
        </>
    )
};

export default ChooseProduct;