import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { CButton } from '@coreui/react';

const urlApi_1 = '/faqs';
const urlRouter = '/useful-materials';

export async function LoaderUsefulMaterialInformation({ params }) {
    if (Object.keys(params).length != 0) {
        // let res = await AxiosInstance.get(`${urlApi_1}/${params.pid}`, {
        let res = await AxiosInstance.get(`${urlApi_1}/${params.pid}`
            // params: {
            // "g[0]": "product:read",
            // "g[1]": "product:admin",
            // }
            // }).then((response) => {
        ).then((response) => {
            // console.log("get /api/usefulMaterials/ response", response);
            return response;
        });
        return res;
    }
    return false;
};

// Інформація про товар (тільки для перегляду)
const UsefulMaterialInformation = () => {

    const { data } = useLoaderData();
    // useEffect(() => {
    //     console.log('data', data);
    // }, [data]);

    // const [userRole, setUserRole] = useState(false);

    const [name, setName] = useState((data != undefined) ? data.name : 'Назва корисного матеріалу');
    const [description, setDescription] = useState((data != undefined) ? data.contentFull : '');
    // const [dateCreated, setDateCreated] = useState((data != undefined) ? data.dateCreated : '2024-03-11');
    // const [dateCreated, setDateCreated] = useState((data != undefined) ? data.dateEntered : '');
    // const [userCreated, setUserCreated] = useState((data != undefined) ? data.userCreated : 'Прізвище та ім\'я користувача');
    // const [userCreated, setUserCreated] = useState((data != undefined) ? data.createdUser : '');
    // const [dateUpdated, setDateUpdated] = useState((data != undefined) ? data.dateModified : '-');
    // const [userUpdated, setUserUpdated] = useState((data != undefined) ? data.modifiedUser : '-');
    // const [description, setDescription] = useState((data != undefined) ? data.description : 'Опис корисного матеріалу');

    return (
        <div>
            <div className="d-flex justify-content-between">
                <h1 className="mb-3">{name}</h1>
                {/* Дозволити редагування тільки ROLE_ADMIN */}
                {
                    // (userRole !== false && userRole === "ROLE_ADMIN") &&
                    <Link to={`${urlRouter}/update/${data.id}`} className="mb-3">
                        <CButton className="mb-3" color="secondary" title="Редагувати матеріал">Редагувати</CButton>
                    </Link>
                }
            </div>

            <div>{description}</div>


            {/* <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className='usefulMaterials-table__th1'>#</th>
                        <th>Значення</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='usefulMaterials-table__td1'>Назва</td>
                        <td>{name}</td>
                    </tr>
                    <tr>
                        <td className='usefulMaterials-table__td1'>Хто створив</td>
                        <td>{userCreated}</td>
                    </tr>
                    <tr>
                        <td className='usefulMaterials-table__td1'>Коли створив</td>
                        <td>{dateCreated}</td>
                    </tr>
                    <tr>
                        <td className='usefulMaterials-table__td1'>Хто редагував</td>
                        <td>{userUpdated}</td>
                    </tr>
                    <tr>
                        <td className='usefulMaterials-table__td1'>Коли редагував</td>
                        <td>{dateUpdated}</td>
                    </tr>
                    <tr>
                        <td className='usefulMaterials-table__td1'>Опис</td>
                        <td dangerouslySetInnerHTML={{ __html: description }}></td>
                        <td>{description}</td>
                    </tr>
                </tbody>
            </Table> */}
        </div>
    );
};

export default UsefulMaterialInformation;