import React, { useState, useEffect} from 'react';
import AxiosInstance from 'src/components/Axios';
import AsyncSelect from 'react-select/async';

const FilterCategory = ({ setData, clear }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        console.log('clear', clear);
        if(clear){
            setData({});
            setSelectedOption({});
        }
    }, [clear])
    // Для поля Користувач (AsyncSelect)
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get(`/categories`, { headers: { 'accept': 'application/json' }, params: {
            "name": inputValue,
            "groups[0]": "cat:read",
            "groups[1]": "cat:parent",
        } }).then((response) => {
            const getCategoryWithParents = (result) => {
                let n = '';
                if(result.parent != undefined){
                    n += ` -> ${result.parent.name}`;
                    n += getCategoryWithParents(result.parent)
                }
                return n;
            }
            return response.data.map((result) => {
                let parent = getCategoryWithParents(result);
                let n = result.name + parent;
                
                return {
                    label: n,
                    value: result.id,
                }
            });
        });
    };
    const handleChange = (selectedOption) => {
        setData(selectedOption);
        setSelectedOption(selectedOption);
    };



    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                value={selectedOption}
                onChange={handleChange}
                className="flex-grow-1"
                placeholder="Категорія"
                // onKeyDown={keyDown}
            />
        </>
    )
}

export default FilterCategory;