import React, { useEffect, useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react';
import { cilPen, cilPlus, cilTrash, cilFile } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Button, Col, FormControl, FormSelect, Row } from 'react-bootstrap';
import MyToastify from 'src/components/myComponents/MyToactify';
import { ToastContainer } from 'react-toastify';
const urlApi = '/form_answers';
const url = 'https://dev-api-lez.inneti.net';
// const formID = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
// console.log('formID', formID)


export async function LoaderFormAnswerItem({ params }) {
    if (Object.keys(params).length != 0) {
        let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
            // console.log('response', response)
            return response;
        });
        return res;
    }
    return false;
};


// Компонент заповненої форм
const FormAnswerItem = () => {

    const { data } = useLoaderData();
    // console.log('data', data);

    // Додані взяті з API
    const [dataFormAnswer, setDataFormAnswer] = useState(false);
    const [dataAnswerFields, setDataAnswerFields] = useState([]);
    // const [comment, setComent] = useState("");
    // const [orderStatus, setOrderStatus] = useState("");
    const [media, setMedia] = useState();
    // let commentID;
    // let statusID;
    const popup = MyToastify();

    // Взяти необідні дані, які прийшли з сервера
    useEffect(() => {
        if (data !== undefined) {
            setDataFormAnswer(data.form);
            setDataAnswerFields(data.formAnswerFields);
        };
        if (data.media.length > 0) {
            setMedia(data.media);
        };
    }, [data]);

    // const addStatus = () => {
    //     AxiosInstance.put(`/form_answer_fields/${statusID}`, { value: orderStatus }).then(() => {
    //         AxiosInstance.get(`${urlApi}/${data.id}`).then((res) => {
    //             // console.log('res', res.data);
    //             if (res.status == 200) {
    //                 popup.toastifySuccess("Статус успішно змінено");
    //             };
    //             setDataAnswerFields(res.data.formAnswerFields);
    //         });
    //     });
    // };

    // const addComment = () => {
    //     AxiosInstance.put(`/form_answer_fields/${commentID}`, { value: comment }).then((resp) => {
    //         if (resp.status == 200) {
    //             popup.toastifySuccess("Коментар успішно додано");
    //         };
    //     }).then(() => {
    //         AxiosInstance.get(`${urlApi}/${data.id}`).then((res) => {
    //             // console.log('res', res.data);
    //             setDataAnswerFields(res.data.formAnswerFields);
    //         });
    //     });
    // };

    return (
        <>
            <ToastContainer />

            <div className='mb-4' style={{ padding: "15px", border: "1px solid #d8dbe0", backgroundColor: "rgba(255, 255, 255, 0.87)", borderRadius: "7px" }}>
                <div className="mb-4">
                    <Row>
                        <Col>
                            <h2>{dataFormAnswer.name}</h2>
                            <p>{dataFormAnswer.description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Дата заповнення</h4>
                            <p>{data.dateEntered.replace('T', ' ').slice(0, -6)}</p>
                        </Col>
                    </Row>
                </div>
                <hr />
                {/* Заповнена форма */}

                {
                    (Array.isArray(dataAnswerFields) && dataAnswerFields.length > 0) &&
                    dataAnswerFields
                        .sort((a, b) => a.id - b.id) // Сортуємо за id
                        .map((field) => {
                            console.log('field', field)
                            if (field.name === 'status') {
                                statusID = field.id;
                            }
                            if (field.name === 'comment') {
                                commentID = field.id;
                            }
                            // console.log('field', field);
                            const value = (field.value !== "") ? field.value : "Не заповнено";
                            return (
                                <div key={field.id} className="mb-3">
                                    <h4>{field.formField.label}</h4>
                                    <p>{value}</p>
                                </div>
                            );
                        })
                }


                {/* Медіа */}
                {
                    (media !== undefined && media.length > 0) &&
                    <div className='mb-3'>
                        <h4 className='mb-3 d-flex'>
                            <span>Прикріплений файл</span>
                            <Link className='ms-3' target='_blank' to={`${url}${media[0].contentUrl}`} preventScrollReset={true}>
                                <CButton color="dark" variant="outline" className='d-flex align-items-center'>
                                    <CIcon icon={cilFile} customClassName="nav-icon" height={20} />
                                </CButton>
                            </Link>
                        </h4>
                    </div>
                }
            </div>

            {/* Коментар менеджера  */}
            {/* <div className='mb-4' style={{ padding: "15px", border: "1px solid #d8dbe0", backgroundColor: "rgba(255, 255, 255, 0.87)", borderRadius: "7px" }}>
                <div className='mb-3'>
                    <h4 className='mb-3 d-flex'>
                        <span>Коментар менеджера</span>
                    </h4>
                    <FormControl placeholder='Введіть коментар' onChange={(e) => setComent(e.target.value)} value={comment}></FormControl>

                    <Button color='primiry' className='mt-3' onClick={addComment}>Надіслати</Button>
                </div>
            </div> */}

            {/* Статус  */}
            {/* <div className='mb-4' style={{ padding: "15px", border: "1px solid #d8dbe0", backgroundColor: "rgba(255, 255, 255, 0.87)", borderRadius: "7px" }}>
                <h4 className='mt-3 d-flex'>
                    <span>Змінити статус</span>
                </h4>
                <FormSelect onChange={(e) => setOrderStatus(e.target.value)} value={orderStatus}>
                    <option >Статус</option>
                    <option>Виконано</option>
                    <option>В роботі</option>
                    <option>Нове</option>
                    <option>Відмінено</option>
                </FormSelect>
                <Button color='primiry' className='mt-3' onClick={addStatus}>Надіслати</Button>
            </div> */}

            {/* <ToastContainer /> */}
        </>
    );
};

export default FormAnswerItem;