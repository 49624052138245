import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import VariationsCategories from 'src/components/attributes/VariationsCategories';
import VariationsCreateForm from 'src/components/attributes/VariationsCreateForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

const urlApi = '/attribute_items';

export async function LoaderVariationsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const VariationsForm = () => {
  const { data } = useLoaderData();
  const [MyData, setMyData] = useState(data);
  // Викликає popup з власного компонента
  const popup = MyToastify();

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // console.log("MyData", MyData);

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <VariationsCreateForm data={MyData} toastifySuccess={popup.toastifySuccess} />
      <hr />
      {(MyData != undefined) ?
        <VariationsCategories variation_id={MyData.id} type="variations" toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} /> : ''}
    </>
  );
};

export default VariationsForm;