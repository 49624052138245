import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import AxiosInstance from 'src/components/Axios';

// Категорії для сторінки інформації товару
const ProductInformationCategories = (props) => {

    // Категорії, які додані до продукту
    const [category, setCatategory] = useState([]);

    // Запит за категоріями, які додані до товару
    useEffect(() => {
        if (props.productId !== undefined) {
            AxiosInstance.get(`/categories`, { headers: { 'accept': 'application/json' }, params:{
                'products.id': props.productId
            } }).then((response) => {
                // console.log("response get products", response);
                setCatategory(response.data)
            });
        }
    }, [props])

    return (
        <div className='mt-4'>
            <h2>Категорії</h2>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className='products-table__th1'>#</th>
                        <th>Категорія</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (category.length > 0) ?
                            category.map((row, index) => {
                                return (
                                    <tr key={row.id}>
                                        <td className='products-table__td1'>{index + 1}</td>
                                        <td>{row.name}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={2}>Категорії відсутні</td>
                            </tr>
                    }
                </tbody>
            </Table>
        </div>
    );
};

export default ProductInformationCategories;