import React, { useState, useEffect } from 'react';
import AxiosInstance from 'src/components/Axios';
import AsyncSelect from 'react-select/async';

// Для відображення назви користувача в полі AsyncSelect
const getUserName = (user) => {
    let fn = (user.firstName != undefined) ? `${user.firstName} ` : '';
    let ln = (user.lastName != undefined) ? `${user.lastName} ` : '';
    let un = (user.username != undefined) ? ` (${user.username})` : '';
    return `${fn}${ln}${un}`;
};

const FilterUser = ({ setData, clear }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        if (clear) {
            setSelectedOption(null);
        }
    }, [clear])
    // Для поля Користувач (AsyncSelect)
    const loadOptions = async (inputValue) => {
        return await AxiosInstance.get(`/users?firstName=${inputValue}&lastName=${inputValue}&username=${inputValue}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: getUserName(result),
                value: result.id,
            }));
        });
    };

    const handleChange = (selectedOption) => {
        setData(selectedOption);
        setSelectedOption(selectedOption);
    };

    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                value={selectedOption}
                onChange={handleChange}
                className="flex-grow-1"
                placeholder="Користувач"
            // onKeyDown={keyDown}
            />
        </>
    )
}

export default FilterUser;