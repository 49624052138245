import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';

// Вкладка "Відео"
const ProductVideo = (props) => {
    // console.log('ProductVideo props', props);

    const [videoList, setVideoList] = useState(props?.MyData?.video ?? []);
    const [video, setVideo] = useState("");
    const [isVideo, setIsVideo] = useState(props?.MyData?.isVideo ?? false);
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit] = useState(true);

    // Заповнити дані при оновленні товару
    useEffect(() => {
        if (props.MyData) {
            setVideoList(props.MyData.video);
            setIsVideo(props.MyData.isVideo);
        };
    }, [props.MyData]);

    // Заповнити дані для посилання на відео
    useEffect(() => {
        if (videoList && videoList.length > 0) {
            setVideo(videoList[0]);
        };
    }, [videoList]);

    // Функція для оновлення даних
    const submitData = () => {
        setIsDisabledBtnSubmit(true);
        const data_response = {
            video: [video],
            isVideo: isVideo,
            active: 'on',
        };

        if (video === "") {
            data_response.isVideo = false;
        };

        props.handleSubmit(event, data_response);
    };

    // Відслідковувати блокування кнопки оновлення даних
    useEffect(() => {
        if (Array.isArray(props?.MyData?.video) && props.MyData.video.length > 0 && props.MyData.video[0] === video && props?.MyData?.isVideo === isVideo) {
            setIsDisabledBtnSubmit(true);
        } else {
            setIsDisabledBtnSubmit(false);
        };
    }, [props.MyData, video, isVideo])

    return (
        <div>
            <CForm>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Посилання на YouTube</CFormLabel>
                    <CFormInput type="text" id="exampleFormControlInput6" value={video == null ? "" : video} onChange={(e) => setVideo(e.target.value)} autoComplete="off" />
                </div>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput9" label="Показувати на сайті" checked={isVideo} onChange={() => setIsVideo(!isVideo)} />
                </div>
                {/* <CButton color="success" onClick={() => props.handleSubmit(event, data_response)}>Зберегти</CButton> */}
                <CButton color="success" onClick={() => submitData(event)} disabled={isDisabledBtnSubmit}>Зберегти</CButton>
            </CForm>
        </div>
    );
};

export default ProductVideo;