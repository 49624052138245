import React, { useEffect, useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton, CRow, CCol, CCard, CCardHeader, CCardBody
} from '@coreui/react';
import {Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import Select     from 'react-select'
import {DataGrid} from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {   
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';

const initialValues = {
    // id: "",
    name: "",
    slug: "",
    orders: 0,
  };

const MenuItemForm = ({menu_id, id, parent_id, actionShowModal, data}) => {

    const [values, setValues] = useState(initialValues);
    console.log(data);
    useEffect(() => {
      if(parent_id != undefined)
          setValues({...values, parent: `api/menu_items/${parent_id}`})
      else
          setValues({...values,  menu : `api/menus/${menu_id}` })
    }, [])
    useEffect(() => {
        if(data != undefined){
          setValues({
            name: data.name,
            slug: data.slug,
            orders: data.orders,
          })
        }
        
    }, [data])
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        setValues({
          ...values,
          [name]: type === "number" ? parseInt(value, 10) : value
        });
      };
      /** Відправка форми */
      const handleSubmit = event => {
        event.preventDefault();
    
        if (data == undefined) {
          
            const response = AxiosInstance.post('/menu_items', values).then((response) => {
              if(response.status == 201){
                actionShowModal(false);
                // navigate(`/menu/update/${response.data.id}`);
              }
            });
        }else{
          const response = AxiosInstance.put('/menu_items/' + data.id, values).then((response) => {
            if(response.status == 200){
              actionShowModal(false);
            }
            // if(response.status == 201){
            //   navigate(`/menu/update/${response.data.id}`);
            // }
          });
        }
            // const response = AxiosInstance.put('/menus/' + data.id, values);
         
        // }
    
      }
    return(
        <>
            <CForm onSubmit={handleSubmit}>

                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                    <CFormInput type="text" name="name" defaultValue={values.name} onChange={handleInputChange}  />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">URL</CFormLabel>
                    <CFormInput type="text" name="slug" defaultValue={values.slug} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
                    <CFormInput type="number" name="orders" defaultValue={values.orders} onChange={handleInputChange} />
                </div>
                <CButton type="submit" color="success">Submit</CButton>
            </CForm>
        </>
    );
}

export default MenuItemForm;