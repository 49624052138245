import { useState, useEffect } from "react";
import { cilSave } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import { Carousel } from 'react-responsive-carousel';

// Оновити конкретний продукт, який вже доданий до замовлення
const UpdateProductItemForPreOrder = (props) => {
    // console.log("UpdateProductItem props", props);

    const urlApi = '/pre_order_products';
    const [counter, setCounter] = useState(props.product.count);
    // Зображення товару
    const name = (props?.product?.product?.name) ? props.product.product.name : "";
    const media = (props?.product?.product?.media.length > 0) ? props.product.product.media : "";
    // const code1c = props?.product?.product?.code1c ? props.product.product.code1c : "";
    // const barcode = props?.product?.product?.barcode ? props.product.product.barcode : "";
    const article = props?.product?.product?.article ? props.product.product.article : "";
    // const measurementUnit = props?.product?.product?.measurement_unit ? props.product.product.measurement_unit.short_name : "";
    const measurementUnit = "шт.";
    // Ціна одиниці товару
    const productPrice = props?.product?.priceForOne ? props.product.priceForOne : "0";
    // // Змінені дані про товар, які будуть додані до замовлення
    const [productData, setProductData] = useState(false);
    // Сума замовлення по товару
    const sum = (productPrice * (isNaN(counter) ? 0 : counter)).toFixed(2);
    // // Заблокувати кнопку "Додати до замовлення"
    const [disabledPutBtn, setDisabledPutBtn] = useState(false);
    const [disabledClearBtn, setDisabledClearBtn] = useState(false);
    const [mainMedia, setMainMedia] = useState(
        (props?.product?.product?.mainMedia && props.product.product.mainMedia != null) ?
            props.product.product.mainMedia
            : false
    );
    // Індекс головного зображення з масиву media
    const [indexMainMedia, setIndexMainMedia] = useState(0);

    // Текст для popup
    const popupTextSuccess_1 = "Дані замовленого товару оновлено";
    const popupTextSuccess_2 = "Відкрито вікно редагування замовлення по товару";
    const popupTextSuccess_3 = "Вікно редагування замовлення по товару закрито";
    const popupTextWarning_1 = "Дані про замовлений товар не змінено";
    const popupTextWarning_2 = "Не коректно введена кількість!";

    // // Вивести повідомлення коли відкривається вікно з інформацією про товар
    // useEffect(() => {
    //     // Popup про успішні зміни
    //     props.toastifySuccess(popupTextSuccess_2);
    // }, [props.product.id]);

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    };
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        } else {
            setCounter(1);
        };
    };

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log("inputChange event", event.target.value);
        // typeof event.target.value = string, перевести в тип number
        console.log(event.target.value);
        // Перетворити з типу string в тип number
        // let value = parseFloat(event.target.value);
        // let count = (value <= 0.001) ? 1 : value;
        setCounter(event.target.value);
    };

    // Перевірка counter на NaN
    useEffect(() => {
        // console.log('counter', counter);

        // Для кнопки "Зберегти" (Оновити дані)
        if (isNaN(counter)) {
            setDisabledPutBtn(true);
        } else {
            setDisabledPutBtn(false);
        };

        if (props.product.count == counter) {
            setDisabledPutBtn(true);
        } else {
            setDisabledPutBtn(false);
        };

        // Для кнопки "Скинути зміни"
        if (props.product.count === counter) {
            setDisabledClearBtn(true);
        } else {
            setDisabledClearBtn(false);
        };
    }, [counter]);

    // Функція для кількості (Зміна кількості шт. продуктів вручну)
    const notEmpty = (event) => {
        if (event.target.value === "") {
            setCounter(1);
        } else {
            return;
        };
    };

    // Формування даних про товар для додавання до замовлення
    useEffect(() => {
        setProductData({
            "count": parseFloat(counter),
            "sumOrdered": parseFloat(sum),
        });
    }, [counter]);

    // Функція для оновлення даних про замовлений товар
    const updateProduct = async () => {
        try {
            const response = await AxiosInstance.put(`${urlApi}/${props.product.id}`, productData);
            // console.log('put pre_order_products response', response);
            // Popup про успішні зміни
            props.toastifySuccess(popupTextSuccess_1);
            // Оновити список з доданими товарами і перерахувати загальну суму замовлення
            props.setIsUpdateProductList(true);
            // Оновити загальну суму замовлення
            props.setIsUpdateSumOrdered(true);
            // Закрити вікно редагування товару
            changeModal();
        } catch (error) {
            console.error("Помилка оновлення даних про товар");
            props.toastifyWarning(popupTextWarning_1);
        };
    };

    // Запит на API для оновлення даних про замовлений товар
    const putProductData = () => {
        let count = parseFloat(productData.count);
        if (count <= 0) {
            props.toastifyWarning(popupTextWarning_2);
            return;
        };

        // Оновити дані про замовлений товар
        updateProduct();
    };

    // Функція для закриття оновлення товару і відкриття модального вікна з списком доданих товарів
    const changeModal = () => {
        // Показати таблицю з доданими до замовлення товарами
        props.setShowTable(false);
        // Popup про успішні зміни (закриття вікна)
        props.toastifySuccess(popupTextSuccess_3);
    };

    // Функція для скидання змінених менеджером значень
    const cancel = () => {
        // Скинути кількість товару
        setCounter(props.product.count);
    };

    // Визначити індекс головного зображення з масиву media
    useEffect(() => {
        // console.log('media', media);
        // console.log('mainMedia', mainMedia);
        if (media != undefined && media.length > 0 && mainMedia != undefined) {
            const index = media.findIndex(item => item.id === mainMedia.id);
            // console.log('index', index);
            if (index !== -1) {
                setIndexMainMedia(index);
            }
        }
    }, [media, mainMedia]);

    return (
        <div style={{ position: "relative", padding: "10px" }}>
            <h2 className="mb-3 mx-2 orders-tableHeader">Редагувати замовлення по товару</h2>
            <span className="orders-updateProductItems__btn-close" onClick={changeModal} title="Закрити вікно редагування замовлення по товару">X</span>
            <h6>{props.product.name}</h6>

            <div className="row">
                <div className="col-xxl-8 col-xl-6">
                    {
                        (media != '') ?
                            <Carousel
                                className="me-3" style={{ height: '100px', width: 'auto' }}
                                showArrows={false} showThumbs={true} showStatus={false} thumbWidth={80}
                                selectedItem={indexMainMedia}
                            >
                                {
                                    // Відобразити всі зображення до продукту
                                    media.map(el => {
                                        return (
                                            <div key={el.id}>
                                                <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                            :
                            <div className="me-3 orders-updateProductItems__photo">
                                <img className="me-3" src={"/no-img.jpg"} height={200} />
                            </div>
                    }
                </div>
                <div className="col-xxl-4 col-xl-6">
                    <div className="orders-update__text">Назва: <strong>{name}</strong></div>
                    <div className="orders-update__text">Артикул: {article}</div>
                    {/* <div className="orders-update__text">Штрихкод: {barcode}</div> */}
                    {/* <div className="orders-update__text">Код1с: {code1c}</div> */}
                    <div className="orders-update__text">
                        <span>Ціна {productPrice} грн. </span>
                    </div>
                    <div className="orders-update__text">Сума: {sum} грн.</div>
                    <div className="mt-1 orders-update__text">
                        <span>Кількість, {measurementUnit}: </span> <br />
                        <div className="mt-2 d-flex">
                            <button className='me-1 orders-update__btn-counter' onClick={productDecrement} >-</button>
                            <input className='text-center orders-update__input-counter' type="number" min="0.001" step="any" value={counter} onChange={inputChange} onBlur={notEmpty} />
                            <button className='ms-1 orders-update__btn-counter' onClick={productIncrement}>+</button>
                        </div>
                    </div>
                    <div className="mt-3 d-flex justify-content-center align-items-center">
                        <button className="me-3" onClick={cancel} title="Скинути внесені зміни" disabled={disabledClearBtn}>Скинути</button>
                        <button
                            onClick={putProductData}
                            style={{ cursor: "pointer" }} title="Зберегти зміни" disabled={disabledPutBtn}>
                            <span className='me-2'>
                                <CIcon icon={cilSave} className="nav-icon" height={18} />
                            </span>
                            <span>Зберегти</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProductItemForPreOrder;