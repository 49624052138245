import React, { useEffect, useState } from "react";

// Функція кліку поза елементом
const useOutsideClick = (elementRef, handler, attached = true) => {
    // elementRef - // Посилання на елемент "Баланс на складі", напр. - const storageBalanceRef = useRef(null); <div ref={storageBalanceRef}>
    // handler - функція, яка буде виконувати певну дію, напр. - закрити вікно // Функція для відривання/закривання вікна з балансом на складі - const toggleIsOpenStorageBalance = () => { setIsOpenStorageBalance(!isOpenStorageBalance);};
    // attached - певне значення, напр. // Чи відкрита інформація про баланс на складі - const [isOpenStorageBalance, setIsOpenStorageBalance] = useState(false);

    useEffect(() => {
        if (!attached) return;

        const handleClick = (e) => {
            if (!elementRef.current) return;
            // Елемент не знаходиться всередині виводу пошуку
            if (!elementRef.current.contains(e.target)) {
                handler();
                // console.log("click поза елементом сортування");
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }

    }, [elementRef, handler, attached])
}

export default useOutsideClick;