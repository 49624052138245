import React, { useEffect, useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton, CRow, CCol, CCard, CCardHeader, CCardBody
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select'
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import MenuItemForm from 'src/components/menu/MenuItemForm';
import BasicModal from 'src/components/Modal';
import Accordion from 'react-bootstrap/Accordion';

import { BsPlusLg, BsSortNumericDown, BsFillTrashFill } from 'react-icons/bs';
import { AiOutlineArrowDown, AiFillEdit } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import '../../App.css';


export async function LoaderMenuUpdate({ params }) {
  if (params.menuId != undefined) {
    let res = await AxiosInstance.get('/menus/' + params.menuId).then((response) => {
      return response;
    });

    return res;
  }
  return false;
}

const initialValues = {
  // id: "",
  name: "",
  type: "",
};
const create = true;

const MenuForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [items, setItems] = useState([]);
  console.log(data);
  /** Дані форми */
  const [values, setValues] = useState(initialValues);
  useEffect(() => {
    if (data != undefined) {

      getItems();
      EditMenu(data);
    }
  }, [data])

  function getItems() {
    AxiosInstance.get('/menu_items?menu.id=' + data.id).then((response) => {
      setItems(response.data['hydra:member']);
    });
  }

  function EditMenu(row) {
    setValues({
      ...values,
      // id: row.id,
      name: row.name,
      type: row.type,
    });
  }


  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setValues({
      ...values,
      [name]: type === "number" ? parseInt(value, 10) : value
    });
  };
  /** Відправка форми */
  const handleSubmit = event => {
    event.preventDefault();

    if (data == undefined) {

      const response = AxiosInstance.post('/menus', values).then((response) => {
        if (response.status == 201) {
          navigate(`/menu/update/${response.data.id}`);
        }
      });
    } else {
      const response = AxiosInstance.put('/menus/' + data.id, values).then((response) => {
        // if(response.status == 201){
        //   navigate(`/menu/update/${response.data.id}`);
        // }
      });
    }
    // const response = AxiosInstance.put('/menus/' + data.id, values);

    // }

  }

  const [showModal, setShowModal] = useState(true);

  const actionShowModal = (rez) => {
    setShowModal(rez);
    if (!rez)
      getItems();
  }

  return (
    <>
      <h1></h1>
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>{(data != undefined) ? data.name : 'Створити'}</strong> <small>*</small>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={handleSubmit}>

                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                  <CFormInput type="hidden" defaultValue={values.id} autoComplete="off" />
                  <CFormInput type="text" name="name" defaultValue={values.name} onChange={handleInputChange} autoComplete="off" />
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="exampleFormControlInput1">Тип</CFormLabel>
                  <CFormInput type="text" name="type" defaultValue={values.type} onChange={handleInputChange} autoComplete="off" />
                </div>
                <CButton type="submit" color="success">Submit</CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>

      </CRow>

      {(data != undefined) &&
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>
                <strong>Пункти меню</strong> <small>*</small>
              </CCardHeader>
              <CCardBody>

                <div className='p-5'>
                  <MyAccordion items={items} getItems={getItems} />
                </div>
                <BasicModal
                  show={showModal}
                  actionShowModal={actionShowModal}
                  title={`Додати пукт меню до - ${data.name}`}
                  btn_name={<><BsPlusLg /> Додати пункт меню</>}
                  content={
                    <MenuItemForm menu_id={data.id} actionShowModal={actionShowModal} key="0" />
                  }
                />
              </CCardBody>






            </CCard>
          </CCol>
        </CRow>
      }
    </>
  );
};

const MyAccordion = ({ items, getItems, key }) => {
  return (
    <>
      <Accordion defaultActiveKey={key}>

        {items.map((d) => {
          return <AccordionItemMenu data={d} getItems={getItems} />
        })
        }

      </Accordion>
    </>
  );
}
function Deleted(row, getItems) {
  confirmAlert({
    title: 'Увага!',
    message: `Ви впевнені що хочите видалити цей "${row.name}" пункт ?`,
    buttons: [
      {
        label: 'Так',
        onClick: () => {
          const response = AxiosInstance.delete(`/menu_items/${row.id}`).then((response) => {
            getItems();
          });
        }
      },
      {
        label: 'Ні',
        onClick: () => {
          return;
        }
      }
    ]
  });
}

const AccordionItemMenu = ({ data, getItems }) => {
  const [showModalItem, setShowModalItem] = useState(true);

  const actionShowModalItem = (rez) => {
    setShowModalItem(rez);
    if (!rez)
      getItems();
  }
  console.log(data);
  return (
    <>
      <Accordion.Item eventKey={data.id}>
        <Accordion.Header>
          {data.name} <small className='SortPunkt'><BsSortNumericDown /> - {data.orders}</small>
        </Accordion.Header>
        <Accordion.Body className='bg-light'>
          <div className='row'>
            <div className='col-3'>
              <BasicModal
                show={showModalItem}
                actionShowModal={actionShowModalItem}
                title={`Додати пукт меню до - ${data.name}`}
                btn_name={<><BsPlusLg /> Додати пункт під меню</>}
                content={
                  <MenuItemForm menu_id={data.id} actionShowModal={actionShowModalItem} parent_id={data.id} />
                }
              />
            </div>
            <div className='col-2'>
              <BasicModal
                show={showModalItem}
                actionShowModal={actionShowModalItem}
                title={`Редагувати пукт меню  - ${data.name}`}
                btn_name={<><AiFillEdit /> Редагувати</>}
                content={
                  <MenuItemForm menu_id={data.id} data={data} actionShowModal={actionShowModalItem} parent_id={data.id} />
                }
              />
            </div>
            <div className='col'>
              <Button variant="danger" onClick={() => Deleted(data, getItems)}><BsFillTrashFill /> Видалити пункт меню</Button>
            </div>
          </div>


          <hr />
          <h5><AiOutlineArrowDown />Пункти під меню "{data.name}": </h5>

          {(data.children != undefined && data.children.length > 0) && <MyAccordion items={data.children} getItems={getItems} key={data.id} />}
        </Accordion.Body>
      </Accordion.Item>
    </>
  )
}
export default MenuForm;
