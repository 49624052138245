import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const urlApi = '/storages';
const urlRouter = '/storage';

const page = 1;
const itemsPerPage = 30;

export async function LoaderStorage({ params }) {
  let res = await AxiosInstance.get(urlApi, {
    params: {
      page: page,
      itemsPerPage: itemsPerPage,
      "g[0]": "storages:read",
      "g[1]": "storages:admin",
    }
  }).then((response) => {
    return response;
  });
  return res;
};

const Storage = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [code1cFilterSearch, setCode1cFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  };

  const getRowsTable = (p = 1, allData = false) => {
    setShowLoader(true);
    let params = {
      itemsPerPage: itemsPerPage,
      page: p,
      "g[0]": "storages:read",
      "g[1]": "storages:admin",
    };

    if (allData == false) {
      if (nameFilterSearch != '') {
        params['name'] = nameFilterSearch;
      };
      if (code1cFilterSearch != '') {
        params['code1c'] = code1cFilterSearch;
      };
    };

    // console.log("params", params);
    AxiosInstance.get(urlApi, { params: params }).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
      setShowLoader(false);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  useEffect(() => {
    if (nameFilterSearch != "" || code1cFilterSearch != "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [nameFilterSearch, code1cFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = (event) => {
    setNameFilterSearch(event.target.value);
  };

  const handleCode1cFilterChange = (event) => {
    setCode1cFilterSearch(event.target.value);
  };
  // ----- End handleFilterChange -----

  // Фільтрування по name
  const handleFilterSearch = (event) => {
    // console.log("handleFilterSearch event", event)
    event.preventDefault();
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setCode1cFilterSearch("");
    setDisabled(true);
    getRowsTable(1, true);
  };

  // function Deleted(row) {
  //   confirmAlert({
  //     title: 'Ви впевнені що хочите видалити цей пункт ?',
  //     message: 'Are you sure to do this.',
  //     buttons: [
  //       {
  //         label: 'Так',
  //         onClick: () => {
  //           const response = AxiosInstance.delete(urlApi + '/' + row.id, header).then((response) => {
  //             getRowsTable();
  //           });
  //         }
  //       },
  //       {
  //         label: 'Ні',
  //         onClick: () => {
  //           return;
  //         }
  //       }
  //     ]
  //   });
  // };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name', headerName: 'Назва', width: 400,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}>{params.row.name}</Link>;
      }
    },
    { field: 'status', headerName: 'Статус', width: 200 },
    { field: 'description', headerName: 'Опис', width: 200 },
    // { field: 'parent', headerName: 'parent', width: 200 },
    { field: 'code1c', headerName: 'code1c', width: 200 },
    // {
    //   field: 'created_at',
    //   headerName: 'Дата',
    //   type: 'date',
    //   width: 160,
    // },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    // {
    //   field: "delete",
    //   headerName: "Видалити",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return <CButton color="danger" variant="outline" onClick={() => {
    //         Deleted(params.row)
    //       }
    //     }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
    //   }
    // },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">

          <CAccordion flush>
            <CAccordionItem>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="my-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>

                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="code1c" id="code1cFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleCode1cFilterChange} value={code1cFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>

                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>

          <CCardHeader>
            <strong>Склади</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={itemsPerPage}
                rowsPerPageOptions={[itemsPerPage]}
                pagination
                rowCount={rowCountState}
                loading={showLoader}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Storage;