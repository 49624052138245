import React, { useState, useEffect } from 'react';

// Додані атрибути та варіації до продукту
const AddedAttributesAndVariations = (props) => {
    // console.log('props AddedAttributesAndVariations', props);

    const [attributesList, setAttributesList] = useState([]);

    // Змінити структуруданих атрибутів та варіацій для продукту
    useEffect(() => {
        if (props.product != undefined) {
            let attributes = [];
            props.product.attributeItems.forEach(item => {
                const addedAttribute = attributes.find(attribute => attribute.id === item.attribute.id);

                if (addedAttribute) {
                    addedAttribute.attributeItems.push({ id: item.id, name: item.name });
                } else {
                    attributes.push({
                        id: item.attribute.id,
                        name: item.attribute.name,
                        attributeItems: [{ id: item.id, name: item.name }],
                    });
                };
            });
            setAttributesList(attributes);
        }
    }, [props.product]);

    return (
        <>
            {
                (attributesList.length > 0) &&
                attributesList.map((attribute, index) => {
                    return (
                        <span key={attribute.id}>{`${attribute.name}: `}
                            {
                                attribute.attributeItems.map((variation, index) => {
                                    return (
                                        <span key={variation.id}>
                                            {variation.name}
                                            {
                                                index != (attribute.attributeItems.length - 1) && ", "
                                            }
                                        </span>
                                    )
                                })
                            }
                            {
                                (index != (attributesList.length - 1)) && " | "
                            }
                        </span>
                    )
                })
            }
        </>
    );
};

export default AddedAttributesAndVariations;