import { useState, useEffect, useRef } from "react";
import { cilBasket, cilCheckCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { roundTheNumber } from "src/components/store/roundTheNumber";
import { CButton, CCard, CCardBody, CCardHeader, CPopover, CRow, CCol } from '@coreui/react';
import { setBalance } from "src/REDUX/orderSlice";
import { MdBalance } from "react-icons/md";
import { useSelector } from "react-redux";
import useOutsideClick from "src/components/store/useOutsideClick";

const MyPopovers = ({ product, reduxBalance, measurement_unit }) => {
    return (
        <div className="orders-choose__count-balance_storages">
            <p className="h6">Ваш резерв на складі:</p>
            {
                (reduxBalance != undefined && reduxBalance.length > 0) ?
                    <ul>
                        {
                            reduxBalance.map((val, index) => {
                                if (val.product != undefined && val.storage != undefined && val.product.id == product.id)
                                    return <li key={index}>{`${val.storage.name} - ${val.count}`} {measurement_unit}</li>;
                            })
                        }
                    </ul>
                    :
                    <p style={{ paddingLeft: "10px" }}>У вас нема резервів!</p>
            }

            <p className="h6">Залишки на складах:</p>
            <ul>
                {(product.productBalanceInStorages != undefined) && product.productBalanceInStorages.map((v, index) => {
                    // if (v.manager === undefined || v.manager === null)
                    return (
                        <li key={index}>
                            {(v.storage != undefined) && v.storage.name} ({(v.manager != undefined) && `${v.manager.firstName} ${v.manager.lastName}`}) - <strong>{v.count}</strong> {measurement_unit}
                        </li>
                    );
                })}

            </ul>
        </div>
    );
}

// Конкретний продукт у вигляді таблиці
const ProductTableItem = (props) => {
    // console.log("ProductTableItem props", props);

    const reduxPrice = useSelector(state => state.price)
    // Кількість товару
    const [counter, setCounter] = useState(1);
    // Зображення товару
    // const cartOrderUrl = (props.product.media != undefined && props.product.media.length > 0) ? `${process.env.REACT_APP_SERVER_URL}${props.product.media[0].contentUrl}` : "";
    const cartOrderUrl = (props.product.media != undefined && props.product.media.length > 0) ?
        `${process.env.REACT_APP_SERVER_URL}${(props.product.mainMedia != null && props.product.mainMedia != undefined) ?
            props.product.mainMedia.contentUrl
            :
            props.product.media[0].contentUrl}`
        :
        "/no-img.jpg";
    // Ціна одиниці товару
    const productPriceR = (props.product != undefined && props.product.price != undefined) ? props.product.price : '0';
    const productPriceG3 = props?.product?.priceCategory?.gurt_4 ? props.product.priceCategory.gurt_4 : '0';
    const productPriceG2 = (props?.product?.priceCategory?.gurt_3) ? props.product.priceCategory.gurt_3 : '0';
    const productPriceG1 = props?.product?.priceCategory?.gurt_2 ? props.product.priceCategory.gurt_2 : '0';
    const productPriceVIP = props?.product?.priceCategory?.gurt_1 ? props.product.priceCategory.gurt_1 : '0';
    // Собівартість за штуку
    const productPriceIncome = (props.product != undefined && props.product.priceIncome != undefined) ? props.product.priceIncome : '0';
    const measurement_unit = (props.product != undefined && props.product.measurement_unit != undefined) ? props.product.measurement_unit.short_name : '';
    // Націнка за товар
    // const productMarkUp = (productPriceIncome == 0) ? 0 : (productPriceR - productPriceIncome);
    // Чи даний товар є в списку замовлень
    const [added, setAdded] = useState(false);
    // Заблокувати можливість змінення кількості
    const [disabledCount, setDisabledCount] = useState(false);
    // Заблокувати кнопку "Додати до замовлення"
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [reserve, setReserve] = useState(false);

    const [isSale, setIsSale] = useState(false);
    const [sale, setSale] = useState(0)

    // Чи відкрита інформація про баланс на складі
    const [isOpenStorageBalance, setIsOpenStorageBalance] = useState(false);
    // Посилання на елемент "Баланс на складі"
    const storageBalanceRef = useRef(null);

    // Відслідковувати зміну додавання/видалення з кошика замовлень
    useEffect(() => {
        let isInArray = false;
        if (props.products != false) {
            props.products.some(el => {
                // Порівняти id - чи є в кошику замовлень
                if (el.product.id === props.product.id) {
                    isInArray = true
                };
            });
        };
        setAdded(isInArray);
        setDisabledCount(isInArray);
    }, [props.products]);
    // useEffect(() => {


    // }, [props.reduxBalance]);
    useEffect(() => {
        let c = 0;
        props.reduxBalance.forEach(val => {
            // console.log(val);
            if (val.product != undefined && val.storage != undefined && val.product.id == props.product.id)
                c += val.count;
        })
        setReserve(c);

    }, [reserve]);

    // Цiни зі знижкою
    useEffect(() => {
        if (reduxPrice != undefined) {
            reduxPrice.map(item => {
                if (item.product.id == props.product.id) {
                    setIsSale(true);
                    setSale(item.price)

                }
            })
        }
    })
    //
    const textStyle = {
        color: isSale ? 'green' : 'black', // Зелений колір, якщо isGreen === true, в іншому випадку - чорний
    };

    // Збільшити кількість на 1
    function productIncrement() {
        setCounter(counter + 1);
    };
    // Зменшити кількість на 1
    function productDecrement() {
        if (counter > 2) {
            setCounter(counter - 1);
        }
        else {
            setCounter(1);
        }
    };

    // Зміна кількості шт. продуктів вручну
    const inputChange = (event) => {
        // console.log('event.target', event.target);
        // console.log("typeof event.target.value", typeof event.target.value);
        // typeof event.target.value = string, перевести в тип number

        // Перетворити з типу string в тип number
        // let value = parseFloat(event.target.value);
        // let count = (value <= 0) ? 1 : value;
        setCounter(event.target.value);
    };

    // Перевірка counter на NaN
    useEffect(() => {
        // console.log('counter', counter);
        if (isNaN(counter)) {
            setDisabledBtn(true);
        } else {
            setDisabledBtn(false);
        };
    }, [counter]);

    // Функція для кількості (Зміна кількості шт. продуктів вручну)
    const notEmpty = (event) => {
        if (event.target.value === "") {
            setCounter(1);
        } else {
            return;
        };
    };

    // Додавання товару до замовлення
    const handleAddToOrder = (product) => {
        let count = parseFloat(counter);
        if (count <= 0) {
            alert('Не вірна кількість!');
            return;
        }
        // console.log('product', product);
        // Вибрати склад
        const storage = product?.storageElement?.storage?.id ? `/api/storages/${product.storageElement.storage.id}` : "/api/storages/78";
        // console.log('storage', storage);
        const orderCount = { ...product, count: count, storage: storage };
        // console.log('orderCount', orderCount);
        props.addToOrder(orderCount);
    };

    // Функція для відривання/закривання вікна з балансом на складі
    const toggleIsOpenStorageBalance = () => {
        setIsOpenStorageBalance(!isOpenStorageBalance);
    };

    // Відслідковувати клік поза елементом "Баланс на складі" для закриття цього вікна
    useOutsideClick(storageBalanceRef, toggleIsOpenStorageBalance, isOpenStorageBalance);

    return (
        <tr>
            <td className="orders-choose__td-img">
                <img src={cartOrderUrl} className="orders-choose__img" />
            </td>
            <td className="orders-choose__td-name w-50">
                <span
                    className="orders-choose__name"
                    onClick={() => props.setSelectedProductId(props.product.id)}
                    title="Показати інформацію про товар"
                >
                    {props.product.name}
                </span>
                <div>Артикул: {props.product.article}</div>
                <div className="orders-choose__text">Штрихкод: {props.product.barcode}</div>
                <p className='text-muted'><strong>Склад: </strong>{(props.product.storageElement != undefined) ? props.product.storageElement.storage.name : '...'}</p>
            </td>
            {/* <td className="orders-choose__text orders-choose__PC">{props.product.code1c}</td> */}
            {/* <td className="orders-choose__text orders-choose__PC">{props.product.barcode}</td> */}
            <td className="orders-choose__count">
                <div ref={storageBalanceRef} className="mb-2">
                    <span className="orders-choose__count-balance__btn-show" onClick={toggleIsOpenStorageBalance} title="Показати баланс на складі">
                        <MdBalance />{(props.product.balance > 0) ? props.product.balance.toFixed(3) : 0}/<strong>{reserve} {measurement_unit}</strong>
                    </span>
                    {isOpenStorageBalance && (
                        <div className="orders-choose__count-balance_container">
                            <div className="orders-choose__count-balance_title-container">
                                <div className="orders-choose__count-balance_title">
                                    <span>{(props.product.balance > 0) ? 'Баланс на складі - ' + props.product.balance.toFixed(3) + ' ' + measurement_unit : 'Нема на складі'}</span>
                                    <span className="orders-choose__count-balance_title-productName">({props.product.name})</span>
                                </div>
                                <button className="orders-choose__count-balance__btn-close" onClick={toggleIsOpenStorageBalance} title="Закрити баланс на складі">X</button>
                            </div>
                            {/* <div > */}
                            <MyPopovers product={props.product} reduxBalance={props.reduxBalance} measurement_unit={props.measurement_unit} />
                            {/* </div> */}
                        </div>
                    )}
                </div>
                <div className="orders-choose__count-1r">
                    <input className='orders-choose__count-input me-1 text-center' type="number" min="0.001" step="any" value={counter} onChange={inputChange} onBlur={notEmpty} disabled={disabledCount} />
                </div>
                <div className="orders-choose__count-2r">
                    <button className='orders-choose__count-button me-1 text-center' onClick={productDecrement} disabled={disabledCount}>-</button>
                    <button className='orders-choose__count-button' onClick={productIncrement} disabled={disabledCount}>+</button>
                </div>
                <div className="orders-choose__cart-mobile">
                    {
                        (!added) ?
                            <button title="Додати товар до замовлення"
                                onClick={() => handleAddToOrder(props.product)} style={{ cursor: "pointer" }} disabled={disabledBtn}
                            >
                                <CIcon icon={cilBasket} customClassName="nav-icon" height={25} />
                            </button>
                            :
                            <CIcon icon={cilCheckCircle} customClassName="nav-icon" height={25} style={{ color: "green" }} />
                    }
                </div>
            </td>
            <td className="orders-choose__text">
                <div className="orders-choose__TC">
                    <span style={textStyle}>рзд- {isSale ? sale : productPriceR}</span>
                    <span>г3 - {roundTheNumber(productPriceG3)}</span>
                    <span>г2 - {roundTheNumber(productPriceG2)}</span>
                    <span>г1 - {roundTheNumber(productPriceG1)}</span>
                    <span>vip- {roundTheNumber(productPriceVIP)}</span>
                    <span>соб- {roundTheNumber(productPriceIncome)}</span>
                </div></td>
            <td className="orders-choose__cart">
                {
                    (!added) ?
                        <button title="Додати товар до замовлення"
                            onClick={() => handleAddToOrder(props.product)} style={{ cursor: "pointer" }} disabled={disabledBtn}
                        >
                            <CIcon icon={cilBasket} customClassName="nav-icon" height={15} />
                        </button>
                        :
                        <CIcon icon={cilCheckCircle} customClassName="nav-icon" height={18} style={{ color: "green" }} />
                }
            </td>
        </tr>
    )

};

export default ProductTableItem;