import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormInput } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { confirmAlert } from 'react-confirm-alert';

const urlApi = '/forms';
const urlRouter = '/builder-forms';

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderCreatedForms({ params }) {
    let res = await AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
        return response;
    });
    return res;
}

const CreatedForms = () => {
    const { data } = useLoaderData();
    const [rows, setRows] = useState(false);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [disabled, setDisabled] = useState(true);

    const [formNameFilterSearch, setFormNameFilterSearch] = useState('');


    if (!rows) {
        setRowCountState(data['hydra:totalItems'])
        setRows(data['hydra:member']);
    }
    // const getRowsTable = (p = 1) => {
    //     AxiosInstance.get(`${urlApi}?page=${p}`).then((response) => {
    //         setRows(response.data['hydra:member']);
    //     });
    // };
    const handlePageChange = (page) => {
        getRowsTable(page + 1);
    };

    // Заблокувати кнопку фільрації якщо не введені символи для пошуку
    const disabledButton = (event) => {
        if (event.target.value) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
                            alert("Форму видалено");
                            getRowsTable();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Назва', width: 300 },
        { field: 'description', headerName: 'Опис', width: 300 },
        // {
        //   field: 'date',
        //   headerName: 'Дата',
        //   type: 'date',
        //   width: 160,
        // },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
            }
        },
        {
            field: "delete",
            headerName: "Видалити",
            sortable: false,
            renderCell: (params) => {
                return <CButton color="danger" variant="outline" onClick={() => {
                    Deleted(params.row)
                }
                }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
            }
        },
    ];

    // фільтри
    const getRowsTable = (p = 1) => {
        // console.log('getRowsTable');
        let request = ``;
        if (formNameFilterSearch != '') {
            request += `&name=${formNameFilterSearch}`;
        };
        // console.log("request", request);
        AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
            setRows(response.data['hydra:member']);
            setRowCountState(response.data['hydra:totalItems']);
        });
    };

    const handleFormNameFilterChange = event => {
        setFormNameFilterSearch(event.target.value);
        disabledButton(event);
    };

    const handleFilterSearch = () => {
        // console.log('handleFilterSearch');
        getRowsTable();
    };

    // Скидання фільтру
    const handleFilterReset = () => {
        setFormNameFilterSearch("");
        setDisabled(true);
        // getRowsTable();
    };

    // Зробити запит за формами після скидання фільтра
    useEffect(() => {
        if (formNameFilterSearch === "") {
            getRowsTable();
        }
    }, [formNameFilterSearch]);

    // Функція для відслідковування натиснення кнопок: "Enter" - виконати пошук; "Escape" - скинути пошук 
    const keyDown = (event) => {
        // Виконати пошук
        if (event.key === 'Enter' && !disabled) {
            event.preventDefault();
            handleFilterSearch(event);
        };
        // Скинути фільтр пошуку
        if (event.key === 'Escape') {
            event.preventDefault();
            handleFilterReset(event);
        };
    };

    return (
        <>
            <CRow>
                <CCol xs={12}>
                    <CCard className="mb-4">
                        <CAccordion flush>
                            <CAccordionItem>
                                <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                                <CAccordionBody>
                                    <CRow className="my-3 mx-2">
                                        <CCol md={9} className="me-auto">
                                            <CRow>
                                                <CCol md={9} className='filter-field'>
                                                    <CFormInput placeholder="Назва" id="NameFilterSearch" aria-label="Пошук" className="me-2"
                                                        onChange={handleFormNameFilterChange} value={formNameFilterSearch}
                                                        onKeyDown={keyDown}
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        <CCol md={3} style={{ textAlign: "end" }}>
                                            <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                                            <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                                        </CCol>
                                    </CRow>
                                </CAccordionBody>
                            </CAccordionItem>
                        </CAccordion>
                        <CCardHeader>
                            <strong>Форми</strong> <small></small>
                        </CCardHeader>
                        <CCardBody>
                            <p className="text-medium-emphasis small">
                                <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
                            </p>
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={30}
                                    rowsPerPageOptions={[30]}
                                    pagination
                                    rowCount={rowCountState}
                                    paginationMode="server"
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </>
    );
};

export default CreatedForms;