import { useEffect, useState } from "react";
import AxiosInstance from "src/components/Axios";
import { DataGrid } from '@mui/x-data-grid';
import {
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CTable,
    CTableBody,
    CTableCaption,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'
import * as dayjs from 'dayjs';

const SellManagerIncomingProducts = ({ filter, countMarkUp }) => {


    const [rows, setRows] = useState([]);
    const [accKeys, setAccKeys] = useState(1);
    useEffect(() => {
        AxiosInstance.get(`/statistics/get`, { params: filter }).then((response) => {
            // console.log("response LoaderAgreements", response);
            setRows(response.data)
            setAccKeys([...response.data.keys()])
            return response;
        });
    }, []);

    return (

        <>
            <h4>менеджер + накладна + товар:</h4>

            <CAccordion activeItemKey={accKeys}>
                {
                    rows.map((products) => {
                        let k1 = Object.keys(products)[0];
                        let k2 = Object.keys(products[Object.keys(products)[0]])[0];
                        return(
                            
                            <CAccordionItem itemKey={k1}>
                                <CAccordionHeader>{products[k1][k2].user_name}</CAccordionHeader>
                                <CAccordionBody>{
                                    Object.keys(products).map((product, key) => {
                                        // console.log(products[product]);
                                        let first = Object.keys(products[product])[0];
                                        return (

                                                    <CAccordion>
                                                        <CAccordionItem itemKey={key}>
                                                            <CAccordionHeader>{products[product][first].code} дата: {dayjs(products[product][first].date_packing.date).format('DD.MM.YYYY')}</CAccordionHeader>
                                                            <CAccordionBody>
                                                                {
                                                                
                                                                    <CTable>
                                                                        <CTableHead>
                                                                            <CTableRow>
                                                                                <CTableHeaderCell scope="col">Назва</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">Код 1с</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">Факт відвантаж. шт.</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">Факт відвантаж. грн</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">Собівартість грн.</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">Дохід</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">середня прихідна ціна</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">середня продажна ціна</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">середня націнка</CTableHeaderCell>
                                                                                <CTableHeaderCell scope="col">націнка у відсотках</CTableHeaderCell>
                                                                            </CTableRow>
                                                                        </CTableHead>
                                                                        <CTableBody>
                                                                            {
                                                                                Object.keys(products[product]).map( (k) => {
                                                                                    let d = countMarkUp(products[product][k].price_income,products[product][k].sum, products[product][k].count);
                                                                                    return (
                                                                                        <CTableRow key={k}>
                                                                                            <CTableHeaderCell>{products[product][k].name}</CTableHeaderCell>
                                                                                            <CTableHeaderCell>{products[product][k].code_1c_product}</CTableHeaderCell>
                                                                                            <CTableDataCell>{parseFloat(products[product][k].count).toFixed(3)}</CTableDataCell>
                                                                                            <CTableDataCell>{parseFloat(products[product][k].sum).toFixed(3)}</CTableDataCell>
                                                                                            <CTableDataCell>{parseFloat(products[product][k].price_income).toFixed(3)}</CTableDataCell>
                                                                                            <CTableDataCell>{parseFloat(d.sum_income).toFixed(3)}</CTableDataCell>
                                                                                            <CTableDataCell>{parseFloat(d.average_incoming_price).toFixed(3)}</CTableDataCell>
                                                                                            <CTableDataCell>{parseFloat(d.average_selling_price).toFixed(3)}</CTableDataCell>
                                                                                            <CTableDataCell>{parseFloat(d.average_markup).toFixed(3)}</CTableDataCell>
                                                                                            <CTableDataCell>{parseFloat(d.markup_in_percent).toFixed(3)}</CTableDataCell>
                                                                                            
                                                                                        </CTableRow>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </CTableBody>
                                                                    </CTable>
                                                                        
                                                                }
                                                            </CAccordionBody>
                                                        </CAccordionItem>
                                                    </CAccordion>


                                                )
                                            })
                                    }
                                </CAccordionBody>
                            </CAccordionItem>
                        )
                    })
                }
            </CAccordion>

        </>
    );

}

export default SellManagerIncomingProducts;