import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CAccordionItem, CAccordionHeader, CAccordionBody, CFormInput, CAccordion, CFormSelect } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { confirmAlert } from 'react-confirm-alert';
import AsyncSelect from 'react-select/async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';


const urlApi = '/form_answers';
const urlRouter = '/form-answer';

const page = 1;
const postPerPage = 0;
const pageSize = 20;
const role = localStorage.getItem('user_role');

export async function LoaderFormsAnswer({ params }) {
    let res = await AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
        return response;
    });
    return res;
}

// Заповнені форми
const FormsAnswer = () => {
    const { data } = useLoaderData();
    const [rows, setRows] = useState(false);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [btnFilterDisabled, setBtnFilterDisabled] = useState(true);
    const [formAnswerName, setFormAnswerName] = useState("");
    const [formForDownload, setFormForDownload] = useState("");
    // Викликає popup з власного компонента
    const popup = MyToastify();
    // const domen = "https://api-felso-brand.inneti.net";
    const domen = process.env.REACT_APP_SERVER_URL;
    // const domen = "https://api-lova-support-business.inneti.net";
    // const domen = "https://api.cp-oplich.com/";
    const [btnDownloadDisabled, setBtnDownloadDisabled] = useState(true);

    if (!rows) {
        setRowCountState(data['hydra:totalItems'])
        setRows(data['hydra:member']);
    }
    const handlePageChange = (page) => {
        getRowsTable(page + 1);
    };

    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
                            alert("Форму видалено")
                            getRowsTable();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        // { field: 'name', headerName: 'Назва', width: 300 },
        {
            field: 'name',
            headerName: 'Назва',
            width: 300,
            // editable: true,
            renderCell: (params) => (
                <>{params.row.form.name}</>
            )
        },
        {
            field: 'description',
            headerName: 'Опис',
            width: 300,
            renderCell: (params) => (
                <>{params.row.form.description}</>
            )
        },
        // {
        //   field: 'date',
        //   headerName: 'Дата',
        //   type: 'date',
        //   width: 160,
        // },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
                return <Link to={`${urlRouter}/review/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
            }
        },
        {
            field: "delete",
            headerName: "Видалити",
            sortable: false,
            renderCell: (params) => {
                return <CButton color="danger" variant="outline" onClick={() => {
                    Deleted(params.row)
                }
                }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
            }
        },
    ];

    // Функція для запиту за формами
    const getRowsTable = (p = 1) => {
        // console.log('getRowsTable');
        let request = ``;
        if (formAnswerName != '') {
            request += `&form.name=${formAnswerName.label}`;
        };
        // console.log("request", request);
        AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
            setRows(response.data['hydra:member']);
            setRowCountState(response.data['hydra:totalItems']);
        });
    };

    // Функція для заповнення значення для вибраної форми для фільтрування та розблокувати кнопку фільтрації
    const handleFormNameFilterSelect = (formAnswerName) => {
        setFormAnswerName(formAnswerName);
        setBtnFilterDisabled(false);
    };

    // Функція для запиту за формами
    const handleFilterSearch = () => {
        getRowsTable();
    };

    // Функція для скидання фільтру
    const handleFilterReset = () => {
        setFormAnswerName(false);
        setBtnFilterDisabled(true);
        // getRowsTable();
    };

    // Зробити запит за формами після скидання фільтра
    useEffect(() => {
        if (formAnswerName === false) {
            getRowsTable();
        }
    }, [formAnswerName]);

    // Функція для вибору форми для завантаження
    const handleNameForDownloadChange = (formName) => {
        setFormForDownload(formName);
    };

    // Розблокувати кнопку завантаденняя заповнених форм
    useEffect(() => {
        // console.log('formForDownload', formForDownload)
        if (formForDownload != "") {
            setBtnDownloadDisabled(false);
        }
    }, [formForDownload]);

    // Функція для завантаження всіх форм
    const loadFormAnswers = () => {
        return AxiosInstance.get('/forms', { headers: { 'accept': 'application/json' } }).then((response) => {
            return response.data.map((result) => ({
                label: result.name,
                value: result.id,
            }));
        });
    };

    // Функція для виведення popup про завантаження форм
    const downloadForm = () => {
        popup.toastifySuccess(`"${formForDownload.label}" завантажується`);
    };

    // Функція для відслідковування натиснення кнопок: "Enter" - виконати пошук; "Escape" - скинути пошук 
    const keyDown = (event) => {
        // Виконати пошук
        if (event.key === 'Enter' && !btnFilterDisabled) {
            event.preventDefault();
            handleFilterSearch(event);
        };
        // Скинути фільтр пошуку
        if (event.key === 'Escape') {
            event.preventDefault();
            handleFilterReset(event);
        };
    };

    return (
        <>
            {/* Popup про успішні зміни (справа зверху) */}
            <ToastContainer />

            <CRow>
                <CCol xs={12}>
                    <CCard className="mb-4">
                        <CAccordion flush>
                            <CAccordionItem>
                                <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
                                <CAccordionBody>
                                    <CRow className="my-3 mx-2">
                                        <CCol md={9} className="me-auto">
                                            <CRow>
                                                <CCol md={9} className='filter-field'>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        loadOptions={loadFormAnswers}
                                                        defaultOptions
                                                        // value={formForDownload}
                                                        value={formAnswerName}
                                                        onChange={handleFormNameFilterSelect}
                                                        className="flex-grow-1 me-1"
                                                        placeholder="Оберіть форму"
                                                        onKeyDown={keyDown}
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        <CCol md={3} style={{ textAlign: "end" }}>
                                            <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={btnFilterDisabled}>Пошук</CButton>
                                            <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                                        </CCol>
                                    </CRow>
                                </CAccordionBody>
                            </CAccordionItem>
                        </CAccordion>

                        <CAccordion flush>
                            <CAccordionItem>
                                <CAccordionHeader><strong>Завантажити дані</strong></CAccordionHeader>
                                <CAccordionBody>
                                    <CRow className="my-3 mx-2">
                                        <CCol md={9} className="me-auto">
                                            <CRow>
                                                <CCol md={9} className='filter-field'>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        loadOptions={loadFormAnswers}
                                                        defaultOptions
                                                        value={formForDownload}
                                                        onChange={handleNameForDownloadChange}
                                                        className="flex-grow-1 me-1"
                                                        placeholder="Оберіть форму"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        <CCol md={3} style={{ textAlign: "end" }}>
                                            <Link to={`${domen}/api/forms/export_csv?id=${formForDownload.value}`}>
                                                <CButton
                                                    className="me-2" type="submit" color="secondary"
                                                    onClick={downloadForm}
                                                    title="Завантажити файл з даними на Ваш комп'ютер"
                                                    disabled={btnDownloadDisabled}
                                                >
                                                    Завантажити
                                                </CButton>
                                            </Link>
                                        </CCol>
                                    </CRow>
                                </CAccordionBody>
                            </CAccordionItem>
                        </CAccordion>

                        <CCardHeader>
                            <strong>Відповіді</strong> <small></small>
                        </CCardHeader>
                        <CCardBody>
                            {/* <p className="text-medium-emphasis small">
                                <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
                            </p> */}
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={30}
                                    rowsPerPageOptions={[30]}
                                    pagination
                                    rowCount={rowCountState}
                                    paginationMode="server"
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </>
    );
};

export default FormsAnswer;