import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { sortObj } from 'src/components/store/sortObj';

// Атрибути для сторінки інформації товару
const ProductInformationAttributes = (props) => {
    // console.log('props', props);

    // Атрибути, які додані до продукту
    const [productAttributes, setProductAttributes] = useState([]);
    // Варіації, які вже додані до продукту
    const attributeItems = props.attributeItems;
    // Для порядкового намера рядка таблиці (1, 2, 3)
    let i = 0;

    // Наповнення масива Атрибутів і вкладених Варіацій відповідно до attribute.id
    useEffect(() => {
        if (attributeItems !== undefined) {
            let attr = [];
            attributeItems.forEach((el) => {
                if (attr[el.attribute.id] === undefined) {
                    attr[el.attribute.id] = {
                        id: el.attribute.id,
                        name: el.attribute.name,
                        variations: [],
                        sort: el.attribute.sort
                    };
                };
                attr[el.attribute.id]['variations'].push({
                    id: el.id,
                    name: el.name,
                    slug: el.slug
                });
            });
            // console.log("attr", attr);
            setProductAttributes(attr);
        }
    }, [attributeItems]);

    return (
        <div className='mt-4'>
            <h2>Атрибути</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th className='products-table__th1'>#</th>
                        <th className='products-table__th2'>Атрибут</th>
                        <th>Варіації</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (productAttributes.length > 0) ?
                            sortObj(productAttributes, "sort").map((row) => {
                                // console.log('row', row);
                                return (
                                    <tr key={row.id}>
                                        <td className='products-table__td1'>{++i}</td>{/* index не можна використовавати, оскільки index в масиві productAttributes не (0, 1, 2) а напр. (2, 5, 11) */}
                                        <td className='products-table__td2'>{row.name}</td>
                                        <td>{row.variations.map((variation) => variation.name).join(", ")}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={3}>Інформація відсутня</td>
                            </tr>
                    }
                </tbody>
            </Table>
        </div>
    );
};

export default ProductInformationAttributes;