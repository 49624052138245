import React, { useState, useEffect } from "react";
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import AxiosInstance from "../Axios";
import { CButton } from "@coreui/react";
import { confirmAlert } from "react-confirm-alert";

const NewsMedia = (props) => {
  // console.log('CategoryMedia props', props);

  const urlApi_2 = "/media_objects";
  const ApiParams = {
    "g[0]": "news:read",
    "g[1]": "news:media",
  };

  const [newMediaId, setNewMediaId] = useState(false);
  const [mediaIdForDelete, setMediaIdForDelete] = useState((props.myData?.media != undefined) ? props.myData.media.id : 0);
  // const [media, setMedia] = useState('');
  const [media, setMedia] = useState((props.myData?.media != undefined) ? props.myData.media : "");
  // Для модального вікна "Додати зображення"
  const [showModalMedia, setShowModalMedia] = useState(true);

  // Текст для popup
  const popupTextSuccess_1 = "Зображення додано";
  const popupTextSuccess_2 = "Зображення видалено";
  const popupTextError = "Помилка запиту";

  // Для модального вікна "Додати зображення"
  const actionShowModalMedia = (rez) => {
    setShowModalMedia(rez);
    // console.log(11111);
    // if (!rez)
    // UpdateMedia();
  };

  // Відслідковувати створення нового зображення
  useEffect(() => {
    if (newMediaId != false) {
      // console.log('newMediaId != false', newMediaId);
      const article = {
        media: `/api/media_objects/${newMediaId}`,
      };
      // Додати зображення до категорії
      addImg(article);
    }
  }, [newMediaId]);

  // Функція для додавання зображення до категорії
  const addImg = (article) => {
    AxiosInstance.put(`${props.urlApi}/${props.myData.id}`, article, { params: ApiParams }).then((response) => {
      // console.log('add img response', response);
      if (response.status === 200) {
        // Якщо до категорії раніше вже було додане зображення, то видалити його з media_objects
        if (mediaIdForDelete != 0) {
          deleteMediaObject();
        };

        // Обнулити нове зображення
        setNewMediaId(false);
        // Оновити зображення для категорії
        setMedia(response.data.media);
        // Підготувати id зображення для можливого видалення
        setMediaIdForDelete(response.data.media.id);
        // Вивести повідомлення про успішні зміни
        props.toastifySuccess(popupTextSuccess_1);
      } else {
        // console.log("Помилка запиту");
        // Вивести повідомлення про помилку зміни
        props.toastifyWarning(popupTextError);
      };
    });
  };

  // Функція для видалення зображення з media_objects
  const deleteMediaObject = () => {
    AxiosInstance.delete(`${urlApi_2}/${mediaIdForDelete}`).then((response) => {
      // console.log('media_objects/id delete response', response);
      if (response.status === 204) {
        // Вивести повідомлення про успішні зміни
        // console.log("Зображення видалено з media_objects");
      } else {
        // console.log("Помилка запиту");
        // Вивести повідомлення про помилку зміни
        props.toastifyWarning(popupTextError);
      }
    });
  };

  // Функція для видалення зображення від категорії
  const deleteImg = () => {
    const article = {
      media: null
    };

    AxiosInstance.put(`${props.urlApi}/${props.myData.id}`, article, { params: ApiParams }).then((response) => {
      // console.log('delete img response', response);
      if (response.status === 200) {
        // Оновити зображення для категорії
        setMedia(response.data.media);
        // Обнулити id зображення для можливого видалення
        setMediaIdForDelete(0);
        // Вивести повідомлення про успішні зміни
        props.toastifySuccess(popupTextSuccess_2);
        // Видалити зображення з media_objects, яке раніше боло додане до категорії 
        deleteMediaObject();
      } else {
        // console.log("Помилка запиту");
        // Вивести повідомлення про помилку зміни
        props.toastifyWarning(popupTextError);
      };
    })
  };

  // Видалити зображення з категорії
  function Deleted() {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити зображення ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            deleteImg();
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  return (
    <>
      <div className="d-flex my-3">
        <div>
          {
            (media != "" && media != undefined) &&
            <img className="me-3" src={(media != "") ? process.env.REACT_APP_SERVER_URL + '' + media.resizeImagings[1].contentUrl : ''} style={{ height: "200px" }} />
          }
        </div>
        <div>
          <div className="mb-3">
            <BasicModal
              show={showModalMedia}
              actionShowModal={actionShowModalMedia}
              title={`Додати зображення до категорії`}
              btn_name={(media == "" || media == undefined) ? "Додати зображення" : "Змінити зображення"}
              content={
                <MediaFrom category_id={props.myData.id}
                  actionShowModal={actionShowModalMedia}
                  toastifySuccess={props.toastifySuccess}
                  type="category" setNewMediaId={setNewMediaId}
                />
              }
            />
          </div>
          {/* Кнопка для видалення зображення */}
          {
            (media != "" && media != undefined) &&
            <div className="mb-3">
              <CButton color="secondary" onClick={() => Deleted()}>Видалити зображення</CButton>
            </div>
          }
        </div>
      </div >
    </>
  );
};

export default NewsMedia;