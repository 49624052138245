import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import DeleteImgFrom from 'src/components/media/DeleteImgFrom';
import SelectMainImg from 'src/components/media/SelectMainImg';
import AxiosInstance from 'src/components/Axios';

const ProductMedia = (props) => {
    // console.log('ProductMedia props', props);

    const urlApi = '/products';
    const ApiParams = {
        "g[0]": "product:read",
        "g[1]": "product:admin",
        "g[3]": "product:category",
        "g[4]": "product:attributeItems",
        "g[5]": "product:media",
    };

    const [media, setMedia] = useState((props.MyData?.media != undefined && props.MyData.length > 0) ? props.MyData.media : '');
    // Для модального вікна "Додати зображення"
    const [showModalMedia, setShowModalMedia] = useState(true);
    // Для модального вікна "Видалення зображення"
    const [showModalMediaDelete, setShowModalMediaDelete] = useState(true);
    // Для модального вікна "Головне зображення"
    const [showModalMediaSelectMain, setShowModalMediaSelectMain] = useState(true);
    // Головне зображення для продукта
    const [mainMedia, setMainMedia] = useState(props.MyData != undefined ? props.MyData.mainMedia : false);
    // Чи є головне зображення для можливого подальшого автоматичного додавання в mainMedia першого доданого зображення
    const [isWasFirstMedia, setIsWasFirstMedia] = useState(false);
    // Індекс головного зображення з масиву media
    const [indexMainMedia, setIndexMainMedia] = useState(0);

    // Відслідковувати чи є головне зображення для можливого подальшого автоматичного додавання в mainMedia першого доданого зображення
    useEffect(() => {
        // console.log('MyData', MyData);
        if (props.MyData?.media != undefined && props.MyData.media.length > 0 && mainMedia != null) {
            setIsWasFirstMedia(true);
        } else {
            setIsWasFirstMedia(false);
        }

        if (props.MyData?.media != undefined && props.MyData.media.length > 0) {
            setMedia(props.MyData.media);
        }
    }, [props.MyData, media, mainMedia]);

    // Відслідковувати: при додаванні першого зображення додавати його до головного
    useEffect(() => {
        if (media.length > 0 && mainMedia == null && !isWasFirstMedia) {
            // console.log("Після додавання першого зображення додати його до головного");
            addMainMedia(media[0].id);
        }
    }, [media]);

    // Функція для додавання головного зображення
    const addMainMedia = (id) => {
        // console.log("addMainMedia() id", id);
        const url = process.env.REACT_APP_SERVER_URL + '/api/products';
        AxiosInstance.put(`${url}/${props.MyData.id}`, { mainMedia: `api/media_objects/${id}` }).then((response) => {
            // console.log("put mainMedia response", response);
            if (response.status === 200) {
                setIsWasFirstMedia(true);
                // Запит на оновлення даних (зображень) продукту
                UpdateMedia();
            };
        });
    };

    // Обновити після додавання/видалення зображення продукту
    const UpdateMedia = () => {
        AxiosInstance.get(`${urlApi}/${props.data.id}`, { params: ApiParams }).then((response) => {
            // console.log("UpdateMedia response", response);
            if (response.status === 200) {
                props.setMyData(response.data);
                setMedia(response.data.media);
                setMainMedia(response.data.mainMedia);
                // setUpdate(true);
            }
        });
    };

    // Для модального вікна "Додати зображення"
    const actionShowModalMedia = (rez) => {
        setShowModalMedia(rez);
        // console.log(11111);
        if (!rez)
            UpdateMedia();
    };

    // Для модального вікна "Видалення зображення"
    const actionShowModalMediaDelete = (rez) => {
        setShowModalMediaDelete(rez);
        // console.log(11111);
        if (!rez)
            UpdateMedia();
    };

    // Для модального вікна "Головне зображення"
    const actionShowModalSelectMain = (rez) => {
        setShowModalMediaSelectMain(rez);
    };

    // Визначити індекс головного зображення з масиву media
    useEffect(() => {
        // console.log('media', media);
        // console.log('mainMedia', mainMedia);
        if (media != undefined && media.length > 0 && mainMedia != undefined) {
            const index = media.findIndex(item => item.id === mainMedia.id);
            // console.log('index', index);
            if (index !== -1) {
                setIndexMainMedia(index);
            } else {
                setIndexMainMedia(0);
            }
        }
    }, [media, mainMedia]);

    return (
        <div className="row mb-3">
            {/* <div className="col-6 d-flex"> */}
            <div className="col-12 d-flex">
                {/* <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + media[0].contentUrl : ''} style={{ height: '300px', width: 'auto' }} /> */}
                <Carousel
                    className="me-3"
                    style={{ height: '300px', width: 'auto' }}
                    showArrows={false}
                    showThumbs={true}
                    width={400}
                    showStatus={false}
                    thumbWidth={80}
                    selectedItem={indexMainMedia}
                >
                    {
                        // Відобразити всі зображення до продукту
                        (media != '' && media.length > 0) &&
                        media.map(el => {
                            // console.log('el', el);
                            return (
                                <div key={el.id}>
                                    <img className="me-3" src={(media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.contentUrl : ''} />
                                </div>
                            )
                        })
                    }
                </Carousel>

                <div>
                    {props.data !== undefined &&
                        <div className="mb-3">
                            <BasicModal
                                show={showModalMedia}
                                actionShowModal={actionShowModalMedia}
                                title={`Додати зображення товара`}
                                btn_name='Додати зображення'
                                content={
                                    <MediaFrom product_id={props.data.id}
                                        actionShowModal={actionShowModalMedia}
                                        LoaderProductsUpdate={props.LoaderProductsUpdate} UpdateMedia={UpdateMedia}
                                        toastifySuccess={props.toastifySuccess}
                                    />
                                }
                            />
                        </div>
                    }
                    <div className="mb-3">
                        {media.length > 0 &&
                            <BasicModal
                                show={showModalMediaDelete}
                                actionShowModal={actionShowModalMediaDelete}
                                title={`Видалити зображення товара`}
                                btn_name='Видалити зображення'
                                content={
                                    <DeleteImgFrom product_id={props.data.id}
                                        actionShowModal={actionShowModalMediaDelete}
                                        media={media} UpdateMedia={UpdateMedia} setShowModalMedia={actionShowModalMediaDelete}
                                        toastifySuccess={props.toastifySuccess}
                                        mainMedia={mainMedia}
                                    />
                                }
                            />
                        }
                    </div>
                    <div className="mb-3">
                        {
                            media.length > 0 &&
                            <>
                                <BasicModal
                                    show={showModalMediaSelectMain}
                                    actionShowModal={actionShowModalSelectMain}
                                    title={`Виберіть головне зображення для товара`}
                                    btn_name='Головне зображення'
                                    content={
                                        <SelectMainImg product_id={props.data.id}
                                            media={media} mainMedia={mainMedia}
                                            UpdateMedia={UpdateMedia} setShowModalMedia={actionShowModalSelectMain}
                                            toastifySuccess={props.toastifySuccess}
                                        />
                                    }
                                />
                                <span>
                                    {
                                        (mainMedia != false && mainMedia != null) ?
                                            <span style={{ color: "green" }}>Головне зображення визначено</span>
                                            :
                                            <span style={{ color: "red" }}>Головне зображення не визначено</span>
                                    }
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductMedia;