import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';

// Вкладка "Наявність"
const ProductAvailability = (props) => {

    const [show, setShow] = useState((props.data != undefined && props.data.show != null) ? props.data.show : false);
    const [isTop, setIsTop] = useState((props.data != undefined && props.data.isTop != null) ? props.data.isTop : false);
    const [isNew, setIsNew] = useState((props.data != undefined && props.data.isNew != null) ? props.data.isNew : false);
    const [balance, setBalance] = useState((props.data != undefined && props.data.balance != null) ? props.data.balance : 0);
    const [productToOrder, setProductToOrder] = useState((props.data != undefined) ? props.data.productToOrder : '0');

    // Для checkbox при ініціалазації
    // const initShow = (show != 1) ? false : true;
    // const initTop = (top != 1) ? false : true;
    // const [changeTop, setChangeTop] = useState(initTop);

    // Для зміни значення checkbox для поля "Хіти продаж"
    // useEffect(() => {
    //   changeTop ? setIsTop(1) : setIsTop(0)
    // }, [changeTop]);

    // Для checkbox при ініціалазації
    const initProductToOrder = (productToOrder != 1) ? false : true;
    const [changeProductToOrder, setChangeProductToOrder] = useState(initProductToOrder);

    // Для зміни значення checkbox для поля "Товар під замовлення"
    useEffect(() => {
        changeProductToOrder ? setProductToOrder(1) : setProductToOrder(0)
    }, [changeProductToOrder]);

    const data_response = {
        isTop: isTop,
        isNew: isNew,
        show: show,
        balance: parseInt(balance),
        productToOrder: parseFloat(productToOrder),
        active: 'on',
    };

    return (
        <div>
            <CForm>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput8" label="Товар продається" checked={show} onChange={() => setShow(!show)} />
                </div>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput9" label="Топ продаж" checked={isTop} onChange={() => setIsTop(!isTop)} />
                </div>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput10" label="Новинка" checked={isNew} onChange={() => setIsNew(!isNew)} />
                </div>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput12" label="Товар під замовлення" checked={initProductToOrder} onChange={() => setChangeProductToOrder(!changeProductToOrder)} />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Баланс</CFormLabel>
                    <CFormInput type="number" id="exampleFormControlInput6" value={balance == null ? "" : balance} onChange={(e) => setBalance(e.target.value)} autoComplete="off" />
                </div>
                <CButton color="success" onClick={() => props.handleSubmit(event, data_response)}>Зберегти</CButton>
            </CForm>
        </div>
    );
};

export default ProductAvailability;