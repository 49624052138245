import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AxiosInstance from '../Axios';
import { FcDocument, FcDownload, FcFullTrash } from 'react-icons/fc';
import {
    CButton
} from '@coreui/react';
import { confirmAlert } from 'react-confirm-alert'; // Import

// import async from 'react-select/dist/declarations/src/async';

const urlApi = '/orders';

const ViewMediaOrders = ({ data, showModalMedia }) => {
    const [media, setMedia] = useState({});
    const UpdateTable = () => {
        AxiosInstance.get(`/media_objects?page=1&itemsPerPage=100&orders.id=${data.id}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            return setMedia(response.data);
        });
    }

    useEffect(() => {
        UpdateTable();

    }, [showModalMedia]);

    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                        const response = AxiosInstance.delete('/media_objects/' + row.id).then((response) => {
                            UpdateTable();
                        });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    return (
        <>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong>#</strong></TableCell>
                            <TableCell align="center"><strong></strong></TableCell>
                            <TableCell align="center"><strong>Назва</strong></TableCell>
                            <TableCell align="center"><strong></strong></TableCell>
                            <TableCell align="center"><strong></strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (media.length > 0) ?
                                media.map((value, key) => {
                                    let i = ++key;
                                    return (
                                        <TableRow>
                                            <TableCell align="center"><strong>{i}</strong></TableCell>
                                            <TableCell align="center"><FcDocument /></TableCell>
                                            <TableCell align="center"><strong>{value.name}</strong></TableCell>
                                            <TableCell align="center"><a href={process.env.REACT_APP_SERVER_URL + '' + value.contentUrl} download><FcDownload /> Загрузити</a></TableCell>
                                            <TableCell align="center"><CButton type="submit" color="danger" onClick={() => { Deleted(value) }}><FcFullTrash /></CButton></TableCell>
                                        </TableRow>
                                    )
                                })
                                : <TableRow><TableCell></TableCell></TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ViewMediaOrders;