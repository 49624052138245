import React, { useState } from 'react';

// Видалення зображення з продукту на сайті
const DeleteImgFromSiteProducts = (props) => {
  // console.log("DeleteImgFromSiteProducts media", media);

  // Закрити форму видалення і відкрити форму підтвердження видалення
  const openDeleteForm = (id) => {
    // Закрити модальне вікно
    props.setShowModalMedia(false);
    // Видалити зображення продукта
    props.Deleted(id);
  };

  return (
    <>
      <div className="my-4">
        Виберіть зображення для видалення
      </div>
      <div className='d-flex justify-content-center'>
        {
          (props.media != '') &&
          props.media.map(el => {
            return (
              <div key={el.id} className="me-2">
                <img
                  className="me-3"
                  src={(props.media != '') ? process.env.REACT_APP_SERVER_URL + '' + el.media.contentUrl : ''}
                  style={{ height: '100px', width: 'auto', cursor: "pointer" }}
                  onClick={() => openDeleteForm(el.id)}
                />
              </div>
            )
          })
        }
      </div>
    </>
  );
};

export default DeleteImgFromSiteProducts;