import { useEffect, useState } from "react";
import AxiosInstance from "src/components/Axios";
import { DataGrid } from '@mui/x-data-grid';


const SellProduct = ({filter, countMarkUp}) => {


    const [rows, setRows] = useState([]);
    useEffect(() => {
        AxiosInstance.get(`/statistics/get`, {params: filter }).then((response) => {
           // console.log("response LoaderAgreements", response);
           setRows(response.data)
           return response;
         });
   },[]);



   const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Назва', width: 300},
    { field: 'code_1c_product', headerName: 'Код 1с', width: 100 },
    { field: 'barcode', headerName: 'Штрихкод', width: 100 },
    { field: 'price_income', headerName: 'Ціна приходу', width: 100 },
    { field: 'price', headerName: 'Ціна', width: 100 },
    { field: 'count', headerName: 'Кількість', width: 100 },
    { field: 'measurement_unit', headerName: 'О.в', width: 50 },
    { field: 'sum', headerName: 'Сума', width: 100 },
    {
        field: "sum_income",
        headerName: "Дохід грн.",
        renderCell: (params) => {
            let d = countMarkUp(params.row.price_income, params.row.sum, params.row.count)
            return d.sum_income;
        },
    },
    {
        field: "average_incoming_price",
        headerName: "середня прихідна ціна",
        renderCell: (params) => {
            let d = countMarkUp(params.row.price_income, params.row.sum, params.row.count)
            return d.average_incoming_price;
        }
    },
    {
        field: "average_selling_price",
        headerName: "середня продажна ціна",
        renderCell: (params) => {
            let d = countMarkUp(params.row.price_income, params.row.sum, params.row.count)
            return d.average_selling_price;
        }
    },
    {
        field: "average_markup",
        headerName: "середня націнка",
        renderCell: (params) => {
            let d = countMarkUp(params.row.price_income, params.row.sum, params.row.count)
            return d.average_markup;
        }
    },
    {
        field: "markup_in_percent",
        headerName: "націнка у відсотках",
        renderCell: (params) => {
            let d = countMarkUp(params.row.price_income, params.row.sum, params.row.count)
            return d.markup_in_percent;
        }
    }
  ];

    return (
        <>
            <h4>Товари:</h4>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                pagination
              />
            </div>
    
        </>
    );

}

export default SellProduct;