import { CButton, CCol, CForm, CFormLabel, CFormSelect, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import AxiosInstance from '../Axios';
import MyToastify from '../myComponents/MyToactify';

// Компонент для зміни статусу замовлення
const PreOrdersStatus = (props) => {
    // console.log('PreOrdersStatus props', props);

    const urlApi = '/pre_orders';
    const [status, setStatus] = useState(props.status);
    const [isDisabledStatusBtn, setIsDisavledStatusBtn] = useState(false); // чи заблоковувати кнопку для зміни статусу

    // Викликає popup з власного компонента
    const popup = MyToastify();

    // Відслідковувати чи заблоковувати кнопку для зміни статусу
    useEffect(() => {
        if (props.status === status) {
            setIsDisavledStatusBtn(true);
        } else {
            setIsDisavledStatusBtn(false);
        }
    }, [props.status, status]);

    // Дані для оновлення замовлення
    const data_response = {
        [props.statusToAPI]: status,
    };

    // Запит на оформлення замовлення
    const updatePreOrder = async () => {
        try {
            const response = await AxiosInstance.put(`${urlApi}/${props.MyData.id}`, data_response);
            // console.log("response", response);
            setIsDisavledStatusBtn(true);
            // Оовити дані замовлення
            props.setMyData(response.data);
            // Popup про успішні зміни
            popup.toastifySuccess(props.popupTextSuccess);
        } catch (error) {
            setIsDisavledStatusBtn(false);
            console.error("Помилка запиту на оформлення замовлення: ", error);
            // Показати повідомлення про помилку
            popup.toastifyWarning(props.popupTextError);
        };
    };

    // Надіслати форму
    const handleSubmit = event => {
        event.preventDefault();
        setIsDisavledStatusBtn(true);
        updatePreOrder();
    };

    return (
        <CForm onSubmit={handleSubmit}>
            <CRow>
                <CCol md={6}>
                    {/* <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel> */}
                    <select className="form-select" value={status} onChange={(e) => setStatus(e.target.value)} >
                        {
                            props.statusList.map((status) => {
                                return (
                                    <option key={status.value} value={status.value}>{status.label}</option>
                                );
                            })
                        }
                    </select>
                </CCol>
                <CCol md={6} style={{ textAlign: "end" }}>
                    <CButton type="submit" color="success" disabled={isDisabledStatusBtn}>Зберегти</CButton>
                </CCol>
            </CRow >
        </CForm>
    );
};

export default PreOrdersStatus;