import React, { useEffect } from "react";
import Table from "react-bootstrap/Table";
import ProductTableItem from "./ProductTableItem";
import { useSelector } from "react-redux";

// Таблиця з товарами для вибору для додавання до замовлення
const ChooseProductTable = (props) => {
    // console.log("props", props);
    const reduxBalance = useSelector(state => state.balance)
    // useEffect(() => {
    //     console.log(reduxBalance);
    // }, [reduxBalance]);
    return (
        <>
            {
                <Table striped bordered hover data-aos="fade-up"
                    className="orders-choose__table"
                >
                    <thead>
                        <tr>
                            <th className="orders-choose__th orders-choose__th-img">Img</th>
                            <th className="orders-choose__th w-50">
                                <div>
                                    <div>Назва</div>
                                    <div>Артикул</div>
                                    {/* Відображати тільки для мобільної версії */}
                                    <div className="orders-choose__mobile">
                                        {/* <div>code1c</div> */}
                                        <div>Штрих-код</div>
                                    </div>
                                </div>
                            </th>
                            {/* <th className="orders-choose__th orders-choose__PC">code1c</th> */}
                            {/* <th className="orders-choose__th orders-choose__PC">Штрих-код</th> */}
                            <th className="orders-choose__th orders-choose__th-count ">Залишки<br />К-сть</th>
                            <th className="orders-choose__th orders-choose__th-price">
                                <div className="orders-choose__TC">
                                    <span>Роздріб</span>
                                    <span>Гурт 3</span>
                                    <span>Гурт 2</span>
                                    <span>Гурт 1</span>
                                    <span>VIP</span>
                                    {/* <span>Націнка</span> */}
                                    <span className="orders-choose__short-PC">Собівартість</span>
                                    <span className="orders-choose__short-mobile">Собіварт</span>
                                </div>
                            </th>
                            <th className="orders-choose__th orders-choose__th-action"></th>
                        </tr>
                    </thead>
                    <tbody className="order-table">
                        {
                            (props.rows != false) &&
                            props.rows.map((row) => {
                                return (
                                    <ProductTableItem key={row.id} product={row} setSelectedProductId={props.setSelectedProductId} addToOrder={props.addToOrder} products={props.products} reduxBalance={reduxBalance} />
                                )
                            })
                        }
                    </tbody>
                </Table>
            }
        </>
    )
};

export default ChooseProductTable;