import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderBanners({ params }) {
  let res = await  axios.get(process.env.REACT_APP_SERVER_URL + '/api/banners?page=' + page).then((response) => {
    return response;
  });
  return res;
}

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Назва', width: 200 },
  { field: 'newsShot', headerName: 'Опис', width: 400 },
  {
    field: 'created_at',
    headerName: 'Дата',
    type: 'date',
    width: 160,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: (params) => {
      return <Link to={'/news/update/' + params.row.id}>Редагувати</Link>;
    }
  },
];
const rows = [];



const Banners = () => {
  const {data}  = useLoaderData();
  const rows = data['hydra:member'];
  const postPerPage = page * data['hydra:totalItems'];
    return (
      <CRow>
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader>
              <strong>Новини</strong> <small>Basic example</small>
            </CCardHeader>
            <CCardBody>
              <p className="text-medium-emphasis small">
                <Link to="/news/create">Створити</Link>
              </p>
              <div style={{ height: 600, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[postPerPage]}
                // checkboxSelection
              />
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
};

export default Banners;
