import { toast } from 'react-toastify';

// Для запуску popup зрізними стилями
const MyToastify = () => {
    // Для запобінання дублюванню popup
    const customId = "custom-id-yes";

    // Функція для запуску popup
    const toastifySuccess = (text) => {
        toast.success(text, {
            toastId: customId
        });
    };

    // Функція для запуску popup
    const toastifyWarning = (text) => {
        toast.warn(text, {
            toastId: customId
        });
    };

    return {
        toastifySuccess,
        toastifyWarning,
    };
};

export default MyToastify;