import React, { useEffect, useRef, useState } from 'react';
import { CForm, CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import { Link, useLoaderData } from 'react-router-dom';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { cilTrash, cilCopy } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import MediaFrom from 'src/components/media/MediaFrom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

export async function LoaderMedia({ params }) {
  let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/media_objects', header).then((response) => {
    return response;
  });
  return res;
}

const Media = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  // const [file, setFile] = React.useState();
  const [page, setPage] = useState(1);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextSuccess = "Забраження видалено";

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  };

  function handleChange(event) {
    setFile(event.target.files[0])
  };

  const handlePageChange = (page) => {
    // const handlePageChange = () => {
    UpdateTable(page + 1);
    setPage(page + 1);
  };

  const UpdateTable = (page) => {
    axios.get(process.env.REACT_APP_SERVER_URL + '/api/media_objects?page=' + page, header).then((response) => {
      console.log(response);
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            // const response = axios.delete(process.env.REACT_APP_SERVER_URL + '/api/media_objects/' + row.id, header).then((response) => {
            axios.delete(process.env.REACT_APP_SERVER_URL + '/api/media_objects/' + row.id, header).then((response) => {
              // Оновити таблицю
              UpdateTable(page);
              // Popup про успішні зміни
              popup.toastifySuccess(popupTextSuccess);
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    {
      field: 'image',
      headerName: 'Image',
      width: 100,

      editable: true,
      renderCell: (params) => <img src={process.env.REACT_APP_SERVER_URL + '' + params.row.contentUrl} style={{ height: '45px', width: 'auto' }} />
    },
    {
      field: 'contentUrl',
      headerName: 'Посилання',
      width: 600,
      renderCell: (params) => {
        return process.env.REACT_APP_SERVER_URL + params.row.contentUrl;
      }
    },
    {
      field: "action_copy",
      headerName: "Copy",
      width: 90,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        return <CButton type="submit" color="dark" onClick={() => {
          navigator.clipboard.writeText(process.env.REACT_APP_SERVER_URL + params.row.contentUrl)
        }
        }><CIcon icon={cilCopy} customClassName="nav-icon" height={20} /></CButton>;
      }
    }, {
      field: "action",
      headerName: "Delete",
      width: 90,
      sortable: false,
      renderCell: (params) => {
        // console.log(params);
        return <CButton type="submit" color="danger" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];
  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      <CCard className="mb-4">
        <CCardHeader>
          <strong>Завантажити зображення</strong> <small>загрузіть і збережіть</small>
        </CCardHeader>
        <CCardBody>
          <MediaFrom toastifySuccess={popup.toastifySuccess} UpdateTable={UpdateTable} type="media" page={page} />
        </CCardBody>
      </CCard>
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Ваші медіа</strong> <small></small>
        </CCardHeader>
        <CCardBody>
          <div style={{ height: 800, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={30}
              rowsPerPageOptions={[30]}
              pagination
              rowCount={rowCountState}
              paginationMode="server"
              onPageChange={handlePageChange}
            />
          </div>
        </CCardBody>
      </CCard>
    </>
  );

}

export default Media;