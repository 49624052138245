import { Link, useLoaderData } from 'react-router-dom';



export async function LoaderOrderPrint ({params})  {
    console.log(params);
    return params;
}


const OrderPrint = () => {
    const { id } = useLoaderData();
    return(
        <>
            <iframe src={process.env.REACT_APP_SERVER_URL + `/api/orders/pdf?id=${id}`} width={'100%'} height={700}></iframe>
        </>
    );
}

export default OrderPrint;