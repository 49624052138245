import React, { useEffect, useRef, useState } from 'react'
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CFormTextarea,
} from '@coreui/react'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Select from 'react-select'
import { DataGrid } from '@mui/x-data-grid'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import AxiosInstance from 'src/components/Axios'
import BasicModal from 'src/components/Modal'
import MediaFrom from '../media/MediaFrom'

const initialValues = {
  // id: "",
  name: '',
  content: '',
  media: '',
  data: [],
  slider: '',
  btnUrl: '',
  mediaUrl: '',
  orders: 0,
  mobileMedia: '',
}

const SlideForm = ({ id, parent_id, actionShowModal, data, toastifySuccess }) => {
  const [values, setValues] = useState(initialValues)
  const [mediaId, setMediaId] = useState()
  const [mediaUrl, setMediaUrl] = useState(false)
  const [mobileMediaId, setMobileMediaId] = useState()
  const [mobileMediaUrl, setMobileMediaUrl] = useState(false)
  const[prevMediaId]=useState(data?.media?.id)
  const[prevMobileMediaId]=useState(data?.mobileMedia?.id)
 
  useEffect(() => {
    if (data && data?.id
      // data != undefined
     ) {
      // console.log(data.orders)
      setValues({
        name: data.name,
        content: data.content,
        media: data.media,
        data: data.data,
        // slider: data.slider,
        btnUrl: data.btnUrl,
        mediaUrl: data.mediaUrl,
        orders: data.orders,
        mobileMedia: data.mobileMedia,
       
      })
      if (data?.mobileMedia?.id != undefined) setMobileMediaId(data?.mobileMedia?.id)
      if (data?.media?.id != undefined) setMediaId(data?.media?.id)
    }
  
  
  }, [data])

  useEffect(() => {
    if ( mediaId != undefined) {
      setValues((values) => ({
        ...values,
        media: `/api/media_objects/${mediaId}`,
      }))
      const response = AxiosInstance
        .get(`/media_objects/${mediaId}`)
        .then((response) => {
          if (response.status == 200) {
             setMediaUrl(response?.data?.contentUrl)
           }
      })
      }
      }, [mediaId])

  useEffect(()=>{
    if ( mobileMediaId != undefined) {
      setValues((values) => ({
        ...values,
        mobileMedia: `/api/media_objects/${mobileMediaId}`,
      }))
      const response = AxiosInstance
        .get(`/media_objects/${mobileMediaId}`)
        .then((response) => {
          if (response.status == 200) {
            setMobileMediaUrl(response?.data?.contentUrl)
           }
        })
    }
  },[mobileMediaId])


  const handleInputChange = (e) => {
    const { name, value, type } = e.target

    setValues((values) => ({
      ...values,
      [name]: type === 'number' ? parseInt(value, 10) : value,
    }))
  }
  /** Відправка форми */
  const handleSubmit = (event) => {
    event.preventDefault()

    if (data?.['id'] == undefined) {
      // parent_id
      values['slider'] = `/api/sliders/${parent_id}`
      if (values.media == '') {
        delete values.media
      }
      if (values.mobileMedia == '') {
        delete values.mobileMedia
      }
      const response = AxiosInstance.post('/slides', values).then((response) => {
        if (response.status == 201) {
          actionShowModal(false)
          // navigate(`/menu/update/${response.data.id}`);
        }
      })
    } else {
      const response = AxiosInstance.put('/slides/' + data.id, values).then((response) => {
        if (response.status == 200) {
          actionShowModal(false)
         if(mediaId!=prevMediaId){
              AxiosInstance.delete(`/media_objects/${prevMediaId}`).then(res=>{
                console.log(`media with id:${prevMediaId} was successfully deleted`)
                
              }).catch(()=>console.error(`there was some error with deleiting media with id:${prevMediaId}`))
         }
         if(mobileMediaId!=prevMobileMediaId){
              AxiosInstance.delete(`/media_objects/${prevMobileMediaId}`).then(res=>{
            console.log(`media with id:${prevMobileMediaId} was successfully deleted`)
            
          }).catch(()=>console.error(`there was some error with deleiting media with id:${prevMobileMediaId}`))
         }
        }
        // if(response.status == 201){
        //   navigate(`/menu/update/${response.data.id}`);
        // }
      })
    }
    // const response = AxiosInstance.put('/menus/' + data.id, values);

    // }
  }

  return (
    <>
    <div className='d-flex gap-3'>
      {mediaUrl && mediaUrl != undefined ? (
        <div>
          <div className='fs-5'>Звичайна</div>
        <img src={process.env.REACT_APP_SERVER_URL + mediaUrl} width={100} height={100} />
        </div>
      ) : (
        null
      )}
      {mobileMediaUrl && mobileMediaUrl != undefined ? (
        <div>
          <div className='fs-5'>Мобільна</div>
        <img src={process.env.REACT_APP_SERVER_URL + mobileMediaUrl} width={100} height={100} />
        </div>
      ) : null}
      </div>
      <label className='d-block fs-5'>Звичайна версія фото</label>
      <MediaFrom
        type="category"
        setNewMediaId={setMediaId}
        toastifySuccess={toastifySuccess}
        
      />
      <label className='d-block fs-5'>Мобільна версія фото</label>
      <MediaFrom
        type="category"
        setNewMediaId={setMobileMediaId}
        toastifySuccess={toastifySuccess}
        
      />
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" name="name" value={values.name} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Контент</CFormLabel>
          <CFormTextarea name="content" value={values.content} onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Посилання для кнопки</CFormLabel>
          <CFormInput
            type="text"
            name="btnUrl"
            value={values.btnUrl}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Посилання для медіа</CFormLabel>
          <CFormInput
            type="text"
            name="mediaUrl"
            value={values.mediaUrl}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
          <CFormInput
            type="number"
            name="orders"
            value={values.orders}
            onChange={handleInputChange}
          />
        </div>
        <CButton type="submit" color="success">
          Submit
        </CButton>
      </CForm>
    </>
  )
}

export default SlideForm
