import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';

const urlApi = '/locations';
const urlRouter = '/locations';
const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}`, 'accept': 'application/json' } }

export async function LoaderLocationUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};

const formReducer = (state, event) => {
  return {
    ...state, 
    [event.target.name]: event.target.value
  }
};

const LocationForm = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [status, setStatus] = useState((data != undefined) ? data.status : '');
  const [description, setDescription] = useState((data != undefined) ? data.description : '');
  // const [parent, setParent] = useState((data != undefined) ? data.parent : '');
  const [c1id, setC1id] = useState((data != undefined) ? data.c1id : '');
  const [code1c, setCode1c] = useState((data != undefined) ? data.code1c : '');
  const editorRef = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();
  
      const data_response = {
        name: name,
        description: description,
      };
      if (data != undefined) {
        const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response, header).then((response) => {
          if (response.status === 200)
            return navigate(urlRouter);
        });
      } else {
        const response = AxiosInstance.post(`${urlApi}`, data_response, header).then((response) => {
          if (response.status === 201)
            return navigate(urlRouter);
        });
      }
    
  };

  return (
    <>
      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off" />
        </div>
        

        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );
};

export default LocationForm;